/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo } from "react";
import {
  Environment,
  Lightformer,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { motion } from "framer-motion-3d";
import Color from "color";
import { Vector2 } from "three";

export default function Bedroom2({
  wallColor = "#A5B986",
  accentColor = "#A5B986",
  floor = "30082",
  floorMaterial,
  sunIntensity,
  lowResMode = false,
  ...props
}) {
  const { nodes, materials } = useGLTF("/rooms/bedroom2w.glb");

  const curtainColor = Color(wallColor).mix(Color(accentColor), 0.2);
  const bedColor = Color(wallColor).mix(Color("#000055"), 0.15);
  const bedColor2 = bedColor.darken(0.3);

  //useHelper(light1, SpotLightHelper);

  const Monstera = useTexture({
    map: "/textures/Monstera" + (lowResMode ? "_low" : "") + ".jpg",
    normalMap:
      "/textures/Monstera_normal" + (lowResMode ? "_low" : "") + ".jpg",
    alphaMap:
      "/textures/Monstera_alpha" + (lowResMode ? "_low" : "") + ".png",
  });
  const Monstera_2 = useTexture({
    map: "/textures/Monstera_2" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_11 = useTexture({
    map: "/textures/Paint_11" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_12 = useTexture({
    map: "/textures/Paint_12" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_13 = useTexture({
    map: "/textures/Paint_13" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_14 = useTexture({
    map: "/textures/Paint_14" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_15 = useTexture({
    map: "/textures/Paint_15" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_16 = useTexture({
    map: "/textures/Paint_16" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_17 = useTexture({
    map: "/textures/Paint_17" + (lowResMode ? "_low" : "") + ".jpg",
  });

  useMemo(() => {
    materials.Wall_07.color.set(wallColor);
    materials.Bed_06.color.set(bedColor.hex());
    materials.Bed_05.color.set(bedColor2.hex());
    materials.Bed_07.color.set(accentColor);
    materials.Wood_06.color.set("#cbb287");
    materials.Monstera.map = Monstera.map;
    materials.Monstera.alphaMap = Monstera.alphaMap;
    materials.Monstera.normalMap = Monstera.normalMap;
    materials.Monstera_02.map = Monstera_2.map;
    materials.Paint_11.map = Paint_11.map;
    materials.Paint_12.map = Paint_12.map;
    materials.Paint_13.map = Paint_13.map;
    materials.Paint_14.map = Paint_14.map;
    materials.Paint_15.map = Paint_15.map;
    materials.Paint_16.map = Paint_16.map;
    materials.Paint_17.map = Paint_17.map;
    materials.Paint_11.map.center = new Vector2(0.5, 0.5);
    materials.Paint_11.map.rotation = -Math.PI / 2;
    materials.Paint_11.map.flipY = false;
    materials.Paint_12.map.center = new Vector2(0.5, 0.5);
    materials.Paint_12.map.flipY = false;
    materials.Paint_13.map.center = new Vector2(0.5, 0.5);
    materials.Paint_13.map.flipY = false;
    materials.Paint_14.map.center = new Vector2(0.5, 0.5);
    materials.Paint_14.map.flipY = false;
    materials.Paint_15.map.center = new Vector2(0.5, 0.5);
    materials.Paint_15.map.flipY = false;
    materials.Paint_16.map.center = new Vector2(0.5, 0.5);
    materials.Paint_16.map.flipY = false;
    materials.Paint_17.map.center = new Vector2(0.5, 0.5);
    materials.Paint_17.map.flipY = false;
  }, [
    Monstera.alphaMap,
    Monstera.map,
    Monstera.normalMap,
    Monstera_2.map,
    Paint_11.map,
    Paint_12.map,
    Paint_13.map,
    Paint_14.map,
    Paint_15.map,
    Paint_16.map,
    Paint_17.map,
    accentColor,
    bedColor,
    bedColor2,
    materials.Bed_05.color,
    materials.Bed_06.color,
    materials.Bed_07.color,
    materials.Monstera,
    materials.Monstera_02,
    materials.Paint_11,
    materials.Paint_12,
    materials.Paint_13,
    materials.Paint_14,
    materials.Paint_15,
    materials.Paint_16,
    materials.Paint_17,
    materials.Wall_07.color,
    materials.Wood_06.color,
    wallColor,
  ]);

  return (
    <group {...props}>
      <Environment frames={1} resolution={256} blur={0.5}>
        {/* Ceiling */}
        <Lightformer
          intensity={0.04}
          rotation-x={Math.PI / 2}
          position={[0, 4, 0]}
          scale={[10, 10, 1]}
        />
        <Lightformer
          intensity={0.5}
          color={"#ffeed2"}
          position={[-5.5, -1, -3]}
          rotation={[0, 0, 0]}
          scale={[1, 4, 2]}
        />
        <Lightformer
          intensity={0.5}
          color={"#ffeed2"}
          position={[-5.5, -1, -0.8]}
          rotation={[0, 0, 0]}
          scale={[1, 4, 2]}
        />
      </Environment>

      <pointLight
        color={"#ffd799"}
        intensity={0.5}
        decay={1.2}
        position={[-4.115, 3.1, -2.913]}
        castShadow
        shadow-bias={-0.005}
        shadow-radius={20}
        shadow-camera-near={0.24}
        shadow-camera-far={5}
        shadow-mapSize-width={lowResMode ? 512 : 1024}
        shadow-mapSize-height={lowResMode ? 512 : 1024}
      />
      {/*<pointLight
        color={"#ffd799"}
        intensity={0}
        decay={1.2}
        position={[-4.115, 3.1, 4.3]}
        castShadow
        shadow-bias={-0.005}
        shadow-radius={20}
        shadow-camera-near={0.24}
        shadow-camera-far={5}
        shadow-mapSize-width={lowResMode ? 512 : 1024}
        shadow-mapSize-height={lowResMode ? 512 : 1024}
  />*/}
      <group position={[-2.7, 6.8, -15]} rotation={[0, 0, 0]}>
        <motion.spotLight
          color={"#ffffff"}
          intensity={sunIntensity}
          angle={0.3}
          penumbra={0}
          decay={0.8}
          castShadow
          shadow-bias={-0.005}
          shadow-radius={50}
          shadow-mapSize-width={lowResMode ? 512 : 1024}
          shadow-mapSize-height={lowResMode ? 512 : 1024}
        />
      </group>
      <mesh position={[0, 5, -5.6]} rotation={[0, 0, 0]}>
        <planeGeometry args={[6, 7]} />
        <meshStandardMaterial
          color={"#ffeed2"}
          emissive={"#ffeed2"}
          emissiveIntensity={0.5}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube090.geometry}
        material={materials.Wall_07}
        position={[-1.683, 4.953, -3.852]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube092.geometry}
        material={materials.Wood_02}
        position={[-0.729, -0.057, -1.062]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube097.geometry}
        material={materials.Sofa}
        position={[-2.099, 0.88, 0.683]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder013.geometry}
        material={materials.Wood_06}
        position={[-4.118, 1.347, -2.901]}
        scale={[0.915, 0.16, 0.915]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere001.geometry}
        material={materials.Wood}
        position={[-4.114, 1.906, -2.916]}
        scale={0.497}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder014.geometry}
        material={materials.Wood_02}
        position={[-4.112, 2.876, -2.912]}
        scale={[0.676, 0.452, 0.676]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003003.geometry}
        material={materials.Bed_06}
        position={[-0.662, 1.254, 0.775]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.02}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane004004.geometry}
        material={materials.Sofa}
        position={[-4.637, 2.061, 0.548]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane009005.geometry}
        material={materials.Bed_06}
        position={[-3.567, 2.068, 0.742]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.02}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane013004.geometry}
        material={materials.Bed_07}
        position={[-2.571, 2.293, 0.876]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.02}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane016004.geometry}
        material={materials.Bed_05}
        position={[-1.618, 2.371, 0.84]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.015}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder015.geometry}
        material={materials.Wood_06}
        position={[-4.15, 0.926, -2.901]}
        scale={[0.166, 0.52, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube100.geometry}
        material={materials.Sofa}
        position={[-0.014, 5.982, -5.471]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube103.geometry}
        position={[-0.049, 4.673, -4.312]}
      >
        <meshPhysicalMaterial
          color={curtainColor.hex()}
          roughness={0.5}
          transmission={0.2}
          sheen={0.5}
          sheenRoughness={0.5}
        />
      </mesh>
      <mesh
        receiveShadow
        geometry={nodes.Cube107.geometry}
        material={materials.Paint_11}
        position={[-5.178, 4.849, 0.727]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube108.geometry}
        material={materials.Wood_06}
        position={[-5.173, 5.635, 0.932]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube109.geometry}
        material={materials.Paint_12}
        position={[-5.178, 6.117, -1.249]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube111.geometry}
        material={materials.Paint_13}
        position={[-5.178, 4.55, -1.249]}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube113.geometry}
        material={materials.Paint_15}
        position={[-5.178, 7.085, 0.444]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube120.geometry}
        material={materials.Paint_17}
        position={[-5.178, 5.511, 2.648]}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube122.geometry}
        material={materials.Paint_16}
        position={[-5.178, 4.251, 2.648]}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube127.geometry}
        material={materials.Paint_14}
        position={[-5.178, 7.085, 2.554]}
        scale={0.809}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder016.geometry}
        material={materials.Sofa}
        position={[-0.006, 8.831, -4.375]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.514, 3.941, 0.514]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube118.geometry}
        material={materials.Wood_06}
        position={[2.044, 1.175, 0.572]}
        scale={[0.055, 0.321, 0.038]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder018.geometry}
        material={materials.Wood_06}
        position={[-0.511, 1.28, -3.509]}
        scale={[0.118, 0.37, 0.118]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03"].geometry}
        material={materials.Monstera}
        position={[-0.648, 3.871, -3.586]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.035}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object001.geometry}
        material={materials.Monstera_02}
        position={[-0.568, 3.28, -3.531]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.035}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder017.geometry}
        material={materials.Wood_06}
        position={[-0.511, 1.99, -3.521]}
        scale={0.526}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder019.geometry}
        position={[-0.51, 2.237, -3.522]}
        scale={[0.389, 0.312, 0.389]}
      >
        <meshStandardMaterial color={"#333"} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder179.geometry}
        material={materials.Wood_06}
        position={[-4.118, 1.347, 4.312]}
        scale={[0.915, 0.16, 0.915]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere014.geometry}
        material={materials.Wood}
        position={[-4.114, 1.906, 4.297]}
        scale={0.497}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder180.geometry}
        material={materials.Wood_02}
        position={[-4.112, 2.876, 4.301]}
        scale={[0.676, 0.452, 0.676]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder181.geometry}
        material={materials.Wood_06}
        position={[-4.15, 0.926, 4.312]}
        scale={[0.166, 0.52, 0.166]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.floor.geometry}
        material={floorMaterial}
        position={[-0.782, 0, -0.995]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials.Wood}
        position={[-0.782, 0, -0.995]}
      />
    </group>
  );
}
