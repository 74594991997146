import axios from "axios";

const baseURL = "https://www.bjelin.com/api/com/";

function unwrapSpecifications(specifications) {
  if (Array.isArray(specifications)) {
    return specifications.reduce((map, [field, rawValue]) => {
      const match = rawValue.match(/^([0-9,.± ]+)\s([^0-9,.±]+)$/);
      if (match) {
        const [, numValue, unit] = match;
        map[field] = { key: field, value: numValue, unit };
      } else {
        map[field] = { key: field, value: rawValue };
      }
      return map;
    }, {});
  }
  return {};
}

function enhanceProduct(entry) {
  entry.specsIndexed = unwrapSpecifications(entry.specs);
  entry.specifications = entry.specs ? [...entry.specs] : [];
  delete entry.specs;

  if (Array.isArray(entry.tags)) {
    entry.tags = entry.tags.map((tag) => tag.name || tag.display);
  } else {
    entry.tags = [];
  }
  return entry;
}

export function loadSettings(lang = "en") {
  const params = {
    lang,
  };
  return new Promise((resolve, reject) => {
    axios
      .create({
        baseURL,
      })
      .get("/settings", { params })
      .then((result) => {
        resolve(result.data || {});
      })
      .catch((err) => {
        console.error("loadSettings", err);
        reject(err);
      });
  });
}

export function loadProducts(lang = "com") {
  const params = {
    lang,
  };
  return new Promise((resolve, reject) => {
    axios
      .create({
        baseURL,
      })
      .get("/products", { params })
      .then((result) => {
        resolve((result.data.products || []).map(enhanceProduct));
      })
      .catch(reject);
  });
}
