import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import useViewportSize from "./useViewportSize";
import { useSpecsTranslations } from "./translationHooks";

export default function FloorSelector({
  floors = [],
  floor,
  setFloor = (floor) => {},
  products = [],
  settings = {},
}) {
  const winDims = useViewportSize();
  const isSmall = winDims.innerWidth < 576 || winDims.innerHeight < 420;
  const ts = useSpecsTranslations();

  const [floorPanelOpen, setFloorPanelOpen] = useState(false);

  let activeFloor = floors.find((f) => f.id === floor);
  if (activeFloor === undefined) {
    activeFloor = floors.find((f) => f.id === "30082");
  }

  const activeProduct = products.find((p) => p.sku === activeFloor.id);
  const specsToDisplay = [
    "Type of floor",
    "Type of wood",
    "Finish",
    "Format",
    "Grading",
    "Staining",
    "Water resistance",
  ];
  const specs = activeProduct
    ? specsToDisplay
        .map((sp) => activeProduct.specsIndexed[sp])
        .map((sp) => ({
          ...sp,
          icon: settings.specifications?.[sp.key].icons?.[sp.value],
        }))
    : [];
  //console.log("specs", specs);

  const motionVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      opacity: 0,
      transition: {
        staggerChildren: 0.03,
        delayChildren: 0.5,
        staggerDirection: -1,
      },
    },
  };
  const childMotionVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        x: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      opacity: 0,
      x: "1em",
      transition: {
        x: { stiffness: 1000 },
      },
    },
  };

  return (
    <>
      <AnimatePresence mode="wait">
        {!floorPanelOpen && (
          <motion.div
            className={"product-info"}
            key={floor}
            variants={motionVariants}
            initial={"closed"}
            animate={"open"}
            exit={"closed"}
          >
            <motion.h2
              style={{ marginTop: 0, marginBottom: 0 }}
              variants={childMotionVariants}
            >
              {activeFloor.label}
            </motion.h2>
            {specs.map((sp) => (
              <motion.div
                className={"spec-row"}
                key={sp.key}
                style={{
                  fontSize: isSmall ? "0.8em" : "1em",
                }}
                variants={childMotionVariants}
              >
                <div
                  className="spec-icon"
                  style={{
                    color: sp.key === "Staining" ? sp.icon.color : "#fff",
                  }}
                  dangerouslySetInnerHTML={{ __html: sp.icon.svg }}
                ></div>
                <div className={"spec-item"}>
                  {!isSmall && (
                    <span className={"spec-key"}>{ts(sp.key)}: </span>
                  )}
                  <span className={"spec-value"}>{ts(sp.key, sp.value)}</span>
                </div>
              </motion.div>
            ))}

            <motion.div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              variants={childMotionVariants}
            >
              <a href={"https://bjel.in/com/art/" + activeFloor.id}>
                Read more
              </a>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        className={"panel-button"}
        key={floor}
        style={{
          bottom: isSmall ? "var(--safe-area-inset-bottom)" : 0,
          right: "4px",
        }}
        animate={{
          x: floorPanelOpen ? (isSmall ? "-7.5em" : "-10em") : 0,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setFloorPanelOpen((open) => !open);
        }}
      >
        <img
          src={"/floors/" + activeFloor.id + "/thumb.jpg"}
          alt={activeFloor.label}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            aspectRatio: "1/1",
            objectFit: "cover",
          }}
        />
        <div className={"panel-button-inner"}>
          {!isSmall && (
            <h4
              style={{
                margin: 0,
              }}
            >
              {activeFloor.label}
            </h4>
          )}
          <div className={"panel-button-inner-box"}>Change floor</div>
        </div>
      </motion.div>
      <motion.div
        className="allow-scroll floor-picker-panel"
        style={{
          fontSize: isSmall ? "0.75em" : "1em",
        }}
        animate={{
          x: floorPanelOpen ? "-10em" : 0,
        }}
      >
        <div className="floor-picker-item-container">
          {floors.map((f) => (
            <div
              className={"floor-picker-item"}
              key={f.id}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFloor(f.id);
                setFloorPanelOpen(false);
              }}
            >
              <img
                src={"/floors/" + f.id + "/thumb.jpg"}
                alt={f.label}
                className={"floor-picker-thumbnail"}
                style={{}}
              />
              <motion.div className={"floor-picker-label"}>
                <h4>{f.label}</h4>
              </motion.div>
              <motion.div
                style={{
                  position: "absolute",
                  top: "2.5em",
                  left: 0,
                  height: "0",
                  weight: "0",
                  border: "1.4rem solid #fff",
                  borderTopColor: "transparent",
                  borderBottomColor: "transparent",
                  borderRightColor: "transparent",
                  opacity: f.id === floor ? 1 : 0,
                }}
                animate={{
                  opacity: f.id === floor ? 1 : 0,
                }}
              ></motion.div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
}
