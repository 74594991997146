import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { QueryParamProvider } from "use-query-params";
import { WindowHistoryAdapter } from "./WindowHistoryAdapter";
import { QueryClient, QueryClientProvider } from "react-query";
import ErrorDisplay from "./ErrorDisplay";
import "./i18next";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      staleTime: Infinity,
      refetchInterval: 1000 * 3600 * 3,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryParamProvider adapter={WindowHistoryAdapter}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary boundaryId="root" fallback={<ErrorDisplay />}>
          <App />
        </ErrorBoundary>
      </QueryClientProvider>
    </QueryParamProvider>
  </React.StrictMode>
);
