// Originally forked from https://codesandbox.io/s/r3f-basic-demo-3izwu?file=/src/App.js:0-2775
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ncscolor from "ncs-color";
import Color from "color";
import { useGesture } from "@use-gesture/react";

export default function ColorPicker({
  isOpen = false,
  close = () => {},
  onChange = (val) => {},
  initBlackness = "20",
  initChroma = "50",
  initHue = "R",
}) {
  const [blackness, setBlackness] = useState(initBlackness);
  const [chroma, setChroma] = useState(initChroma);
  const [hue, setHue] = useState(initHue);

  const hues = useMemo(() => {
    return [
      { ncs: "N" },
      { ncs: "Y" },
      { ncs: "Y10R" },
      { ncs: "Y20R" },
      { ncs: "Y30R" },
      { ncs: "Y40R" },
      { ncs: "Y50R" },
      { ncs: "Y60R" },
      { ncs: "Y70R" },
      { ncs: "Y80R" },
      { ncs: "Y90R" },
      { ncs: "R" },
      { ncs: "R10B" },
      { ncs: "R20B" },
      { ncs: "R30B" },
      { ncs: "R40B" },
      { ncs: "R50B" },
      { ncs: "R60B" },
      { ncs: "R70B" },
      { ncs: "R80B" },
      { ncs: "R90B" },
      { ncs: "B" },
      { ncs: "B10G" },
      { ncs: "B20G" },
      { ncs: "B30G" },
      { ncs: "B40G" },
      { ncs: "B50G" },
      { ncs: "B60G" },
      { ncs: "B70G" },
      { ncs: "B80G" },
      { ncs: "B90G" },
      { ncs: "G" },
      { ncs: "G10Y" },
      { ncs: "G20Y" },
      { ncs: "G30Y" },
      { ncs: "G40Y" },
      { ncs: "G50Y" },
      { ncs: "G60Y" },
      { ncs: "G70Y" },
      { ncs: "G80Y" },
      { ncs: "G90Y" },
    ]
      .map((c) => ({
        ...c,
        rgb: ncscolor.hex("NCS " + blackness + chroma + "-" + c.ncs),
      }))
      .map((c) => ({
        ...c,
        isLight: Color(c.rgb).isLight(),
      }));
  }, [blackness, chroma]);

  const blacks = useMemo(() => {
    return [
      { ncs: "05" },
      { ncs: "10" },
      { ncs: "20" },
      { ncs: "30" },
      { ncs: "40" },
      { ncs: "50" },
      { ncs: "60" },
      { ncs: "70" },
      { ncs: "80" },
      { ncs: "90" },
    ]
      .map((c) => ({
        ...c,
        rgb: ncscolor.hex("NCS " + c.ncs + chroma + "-" + hue),
      }))
      .map((c) => ({
        ...c,
        isLight: Color(c.rgb).isLight(),
      }));
  }, [chroma, hue]);

  const chromas = useMemo(() => {
    return [
      { ncs: "00" },
      { ncs: "05" },
      { ncs: "10" },
      { ncs: "20" },
      { ncs: "30" },
      { ncs: "40" },
      { ncs: "50" },
      { ncs: "60" },
      { ncs: "70" },
      { ncs: "80" },
      { ncs: "90" },
    ]
      .map((c) => ({
        ...c,
        rgb: ncscolor.hex("NCS " + blackness + c.ncs + "-" + hue),
      }))
      .map((c) => ({
        ...c,
        isLight: Color(c.rgb).isLight(),
      }));
  }, [blackness, hue]);

  const selectedColor = {
    ncs: "NCS " + blackness + chroma + "-" + hue,
    rgb: ncscolor.hex("NCS " + blackness + chroma + "-" + hue),
  };

  const blacknessScroller = useRef(null);
  const hueScroller = useRef(null);
  const chromaScroller = useRef(null);
  const PIXELS_PER_STEP = 67.3;

  useLayoutEffect(() => {
    blacknessScroller.current?.scrollTo(
      0,
      blacks.findIndex((v) => v.ncs === blackness) * PIXELS_PER_STEP
    );
    chromaScroller.current?.scrollTo(
      0,
      chromas.findIndex((v) => v.ncs === chroma) * PIXELS_PER_STEP
    );
    hueScroller.current?.scrollTo(
      0,
      hues.findIndex((v) => v.ncs === hue) * PIXELS_PER_STEP
    );
  });

  const onPan =
    (array = hues, setState = setHue, state = hue, scroller = hueScroller) =>
    ({ movement: [x, y] }) => {
      const stepChange = Math.round(y / PIXELS_PER_STEP);
      const curIndex = array.findIndex((v) => v.ncs === state);
      const newIndex = Math.max(
        0,
        Math.min(array.length - 1, curIndex - stepChange)
      );
      scroller.current?.scrollTo(0, newIndex * PIXELS_PER_STEP);
    };
  const onPanEnd =
    (array = hues, setState = setHue, state = hue, scroller = hueScroller) =>
    ({ movement: [x, y], event }) => {
      const stepChange = Math.round(y / PIXELS_PER_STEP);
      const curIndex = array.findIndex((v) => v.ncs === state);
      const newIndex = Math.max(
        0,
        Math.min(array.length - 1, curIndex - stepChange)
      );
      let newState;
      if (newIndex === curIndex) {
        newState = event.target.innerText;
      } else {
        newState = array[newIndex].ncs;
      }
      setState(newState);
      scroller.current?.scrollTo({
        left: 0,
        top: newIndex * PIXELS_PER_STEP,
        behavior: "smooth",
      });
    };

  const bindBlacknessGestures = useGesture({
    onDrag: (state) =>
      onPan(blacks, setBlackness, blackness, blacknessScroller)(state),
    onDragEnd: (state) =>
      onPanEnd(blacks, setBlackness, blackness, blacknessScroller)(state),
  });
  const bindChromaGestures = useGesture({
    onDrag: (state) => onPan(chromas, setChroma, chroma, chromaScroller)(state),
    onDragEnd: (state) =>
      onPanEnd(chromas, setChroma, chroma, chromaScroller)(state),
  });
  const bindHueGestures = useGesture({
    onDrag: (state) => onPan(hues, setHue, hue, hueScroller)(state),
    onDragEnd: (state) => onPanEnd(hues, setHue, hue, hueScroller)(state),
  });

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "#3e3e3e",
            color: "#fff",
            zIndex: 100,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            style={{
              position: "absolute",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.4em",
              columnGap: "0.2em",
              userSelect: "none",
            }}
          >
            <div style={{ width: "2.4em", fontWeight: "bold" }}>NCS</div>
            <div
              {...bindBlacknessGestures()}
              ref={blacknessScroller}
              style={{
                height: "90vh",
                overflowY: "hidden",
                touchAction: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "calc(45vh - 1.5em)",
                  paddingBottom: "calc(45vh - 1.5em)",
                }}
              >
                {blacks.map((v) => (
                  <div
                    key={v.ncs}
                    style={{
                      backgroundColor: v.rgb,
                      width: "3.2em",
                      height: "3em",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.4em",
                      fontWeight: v.ncs === blackness ? "bold" : "normal",
                      color: v.isLight
                        ? "rgba(0,0,0," +
                          (v.ncs === blackness ? "1" : "0.5") +
                          ")"
                        : "rgba(255,255,255," +
                          (v.ncs === blackness ? "1" : "0.5") +
                          ")",
                    }}
                  >
                    {v.ncs}
                  </div>
                ))}
              </div>
            </div>
            <div
              {...bindChromaGestures()}
              ref={chromaScroller}
              style={{
                height: "90vh",
                overflowY: "hidden",
                touchAction: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "calc(45vh - 1.5em)",
                  paddingBottom: "calc(45vh - 1.5em)",
                }}
              >
                {chromas.map((v) => (
                  <div
                    key={v.ncs}
                    style={{
                      backgroundColor: v.rgb,
                      width: "3.2em",
                      height: "3em",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.4em",
                      fontWeight: v.ncs === chroma ? "bold" : "normal",
                      color: v.isLight
                        ? "rgba(0,0,0," + (v.ncs === chroma ? "1" : "0.5") + ")"
                        : "rgba(255,255,255," +
                          (v.ncs === chroma ? "1" : "0.5") +
                          ")",
                    }}
                  >
                    {v.ncs}
                  </div>
                ))}
              </div>
            </div>
            <div
              {...bindHueGestures()}
              ref={hueScroller}
              style={{
                height: "90vh",
                overflowY: "hidden",
                touchAction: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "calc(45vh - 1.5em)",
                  paddingBottom: "calc(45vh - 1.5em)",
                }}
              >
                {hues.map((v) => (
                  <div
                    key={v.ncs}
                    style={{
                      backgroundColor: v.rgb,
                      width: "3.2em",
                      height: "3em",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.4em",
                      fontWeight: v.ncs === hue ? "bold" : "normal",
                      color: v.isLight
                        ? "rgba(0,0,0," + (v.ncs === hue ? "1" : "0.5") + ")"
                        : "rgba(255,255,255," +
                          (v.ncs === hue ? "1" : "0.5") +
                          ")",
                    }}
                  >
                    {v.ncs}
                  </div>
                ))}
              </div>
            </div>
            <motion.div
              style={{
                width: "4em",
                height: "4em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedColor.rgb,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={(e) => {
                onChange("" + blackness + chroma + "-" + hue);
              }}
            >
              <motion.div
                style={{
                  fontSize: "2em",
                  color: Color(selectedColor.rgb).isLight() ? "#000" : "#fff",
                }}
              >
                OK
              </motion.div>
            </motion.div>
          </div>
          <motion.div
            style={{
              position: "absolute",
              bottom: "calc(2em + var(--safe-area-inset-top))",
              right: "calc(2em + var(--safe-area-inset-right))",
              width: "3.2em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              role="img"
              viewBox="0 0 320 512"
              fill="currentColor"
              style={{ height: "2.5em" }}
              onClick={(e) => {
                close();
              }}
            >
              <path d="M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z"></path>
            </svg>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
