import * as React from "react";
import { useFrame, useThree } from "@react-three/fiber";
import mergeRefs from "react-merge-refs";
import { transform } from "framer-motion";
import { motion } from "framer-motion-3d";

export const MotionCamera = React.forwardRef(
  ({ makeDefault = true, ...props }, ref) => {
    const set = useThree(({ set }) => set);
    const camera = useThree(({ camera }) => camera);
    const size = useThree(({ size }) => size);
    const cameraRef = React.useRef();
    const transformerY = transform([6, 2], [0, -0.5]);
    const transformerZ = transform([6, 2], [0, 1]);

    React.useLayoutEffect(() => {
      if (!props.manual) {
        cameraRef.current.aspect = size.width / size.height;
      }
    }, [size, props]);

    React.useLayoutEffect(() => {
      cameraRef.current.updateProjectionMatrix();
    });

    React.useLayoutEffect(() => {
      if (makeDefault) {
        const oldCam = camera;
        set(() => ({ camera: cameraRef.current }));
        return () => set(() => ({ camera: oldCam }));
      }
      // The camera should not be part of the dependency list because this components camera is a stable reference
      // that must exchange the default, and clean up after itself on unmount.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraRef, makeDefault, set]);

    useFrame((state, delta) => {
      const camZ = props.position[2].get();
      const y = transformerY(camZ);
      const z = transformerZ(camZ);
      cameraRef.current.lookAt(0, y, z);
    });

    return (
      <motion.perspectiveCamera ref={mergeRefs([cameraRef, ref])} {...props} />
    );
  }
);
