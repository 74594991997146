import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { animate, motion, useMotionValue, useTransform } from "framer-motion";
import { useGesture } from "@use-gesture/react";
import useViewportSize from "./useViewportSize";
import ncscolor from "ncs-color";
import debounce from "lodash/debounce";
import EditorCanvas from "./EditorCanvas";
import { usePlayingTime } from "./usePlayingTime";
import FloorSelector from "./FloorSelector";
import ColorSelector from "./ColorSelector";
import { hueRotate, parseNCS } from "./ncs";
import RoomSelector from "./RoomSelector";
import { invalidate } from "@react-three/fiber";
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { useQuery } from "react-query";
import { loadProducts, loadSettings } from "./loadProducts";
import * as THREE from "three";

const SUN_INTENSITY_SUNNY = 8;
const SUN_INTENSITY_CLOUDY = 1.2;

export default function RoomEditor() {
  const winDims = useViewportSize();
  const isSmall = winDims.innerWidth < 576 || winDims.innerHeight < 420;

  const [roomType, setRoomType] = useQueryParam(
    "room",
    withDefault(StringParam, "livingroom")
  );
  const [wallColor, setWallColor] = useQueryParam(
    "wall-color",
    withDefault(StringParam, "5912-R09B")
  );
  const [accentColor, setAccentColor] = useState("2050-Y90R");
  const [floor, setFloor] = useQueryParam(
    "floor",
    withDefault(StringParam, "30082")
  );
  const sunIntensity = useMotionValue(SUN_INTENSITY_SUNNY);
  const autoPan = useMotionValue(0);
  const zoom = useMotionValue(0);
  const originPanX = useRef(0);
  const originPanY = useRef(0);
  const isPanning = useRef(false);
  const panX = useMotionValue(0);
  const panY = useMotionValue(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const isManuallyStopped = useRef(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [sunOn, setSunOn] = useState(true);
  const [frameLoopOn, _setFrameLoopOn] = useState(true);
  const [lowPerfMode, _setLowPerfMode] = useState(true);
  const [roomPanelOpen, setRoomPanelOpen] = useState(false);
  const [perfOn] = useQueryParam("perf", BooleanParam);
  const [hideLogo] = useQueryParam("hide-logo", BooleanParam);

  const isWebkit =
    typeof window.webkitConvertPointFromNodeToPage === "function";
  const isIoS = [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);
  const lowResMode = isWebkit && isIoS;
  //console.log("lowResMode", lowResMode, isWebkit, isIoS);

  let clock = new THREE.Clock();
  let delta = 0;
  let interval = 1 / 40;
  function update() {
    delta += clock.getDelta();

    if (delta > interval) {
      // The draw or time dependent code are here
      invalidate();
      delta = delta % interval;
    }
  }

  const debouncedStopRender = useMemo(
    () =>
      debounce((q) => {
        _setFrameLoopOn(false);
      }, 2000),
    [_setFrameLoopOn]
  );
  const startRender = useCallback(() => {
    debouncedStopRender.cancel();
    _setFrameLoopOn(true);
  }, [debouncedStopRender]);

  const setLowPerfMode = useCallback(
    (v) => {
      /*console.log("setLowPerfMode", v);*/
      if (v !== lowPerfMode) {
        /*console.log("lowPerfMode change:", v);*/
        _setLowPerfMode(v);
      }
      if (!v) {
        startRender();
      } else {
        debouncedStopRender();
      }
      if (!isPanning.current && !isManuallyStopped.current) {
        animate(autoPan, v ? 0 : 1, {
          duration: 0.3,
          onUpdate: (v) => {
            update();
          },
        });
        setIsPlaying(!v);
      }
    },
    [autoPan, debouncedStopRender, lowPerfMode, startRender]
  );

  useLayoutEffect(() => {
    startRender();
  }, [winDims.innerWidth, winDims.innerHeight, startRender]);

  const onZoomClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      startRender();
      animate(zoom, isZoomed ? 0 : 1, {
        duration: 2,
        onUpdate: (v) => {
          update();
        },
        //onUpdate: (latest) => console.log(latest),
      });
      setIsZoomed((zoomed) => !zoomed);
      debouncedStopRender();
    },
    [debouncedStopRender, isZoomed, startRender, zoom]
  );
  const onPlayClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      startRender();
      animate(autoPan, isPlaying ? 0 : 1, {
        duration: 0.3,
        onUpdate: (v) => {
          update();
        },
        //onUpdate: (latest) => console.log(latest),
      });
      isManuallyStopped.current = !!isPlaying;
      if (isPlaying) debouncedStopRender();
      setIsPlaying((isPlaying) => !isPlaying);
    },
    [autoPan, debouncedStopRender, isPlaying, startRender]
  );
  const time = usePlayingTime(isPlaying);
  const velocityX = 1 / 6500;
  const velocityY = 1 / 4000;
  const velocityZ = 1 / 3500;

  const vX = useTransform(
    [time, autoPan, panX],
    ([t, autoPan, panX]) =>
      autoPan * (Math.PI / 4) * Math.sin(t * velocityX) +
      ((1 - autoPan) * (panX * Math.PI)) / 2
  );
  const vY = useTransform(
    [time, autoPan, panY],
    ([t, autoPan, panY]) =>
      Math.PI / 8 +
      (autoPan * (Math.PI / 8) * Math.sin(t * velocityY) +
        ((1 - autoPan) * (panY * Math.PI)) / 4)
  );
  const distance = useTransform(
    [time, autoPan, zoom],
    ([t, autoPan, zoom]) =>
      (6.5 - 4 * zoom) * (1 + 0.1 * autoPan * Math.sin(t * velocityZ))
  );
  const camX = useTransform(
    [vX, distance],
    ([vX, distance]) => distance * Math.sin(vX)
  );
  const camY = useTransform(
    [vY, distance],
    ([vY, distance]) => distance * Math.sin(vY)
  );
  const camZ = useTransform(
    [vX, vY, distance],
    ([vX, vY, distance]) => distance * Math.cos(vX) * Math.cos(vY)
  );

  const colors = useMemo(() => {
    return [
      { label: "Custom", ncs: "CUSTOM" },
      { label: "Bjelin Grey", ncs: "5000-N" },
      { label: "Bjelin Dark Grey", ncs: "8000-N" },
      { label: "Neutral White", ncs: "0500-N" },
      { label: "Coastal Blue", ncs: "5411-B02G" },
      { label: "Ocean Air", ncs: "3209-R97B" },
      { label: "Cityscape", ncs: "4307-B83G" },
      { label: "Green Tea", ncs: "3916-G73Y" },
      { label: "Vintage Brown", ncs: "5505-Y46R" },
      { label: "Spruce Shade", ncs: "3007-Y04R" },
      { label: "Dark Beach Sand", ncs: "2005-Y10R" },
      { label: "Mellow", ncs: "5511-Y59R" },
      { label: "Yellow Antique", ncs: "2020-Y20R" },
      { label: "Welcoming Red", ncs: "4429-Y72R" },
      { label: "Silky Pink", ncs: "2911-Y82R" },
      { label: "Earthy Brown", ncs: "6815-Y24R" },
      { label: "Impression", ncs: "3210-Y25R" },
      { label: "Adventure", ncs: "4518-Y38R" },
      { label: "Wisdom", ncs: "7303-Y94R" },
      { label: "Northern Mystic", ncs: "6508-G39Y" },
      { label: "Serene Blue", ncs: "5307-B38G" },
      { label: "Dusty Purple", ncs: "5912-R09B" },
      { label: "Velvet Grey", ncs: "6005-Y13R" },
    ].map((c) => ({ ...c, rgb: ncscolor.hex("NCS " + c.ncs) }));
  }, []);

  const rooms = useMemo(
    () => [
      {
        id: "livingroom",
        label: "Livingroom",
        defaultColor: "7303-Y94R",
      },
      { id: "bedroom", label: "Bedroom", defaultColor: "3916-G73Y" },
      { id: "kitchen", label: "Kitchen", defaultColor: "4429-Y72R" },
      { id: "childroom", label: "Kid's room", defaultColor: "2911-Y82R" },
      { id: "office", label: "Office", defaultColor: "4518-Y38R" },
      { id: "showroom", label: "Showroom", defaultColor: "5000-N" },
    ],
    []
  );

  const formats = useMemo(
    () => ({
      XXL: {
        repeatX: 0.8,
        repeatY: 1.75,
      },
      XL: {
        repeatX: 0.86,
        repeatY: 2.3,
      },
      L: {
        repeatX: 0.86,
        repeatY: 2.63,
      },
      M: {
        repeatX: 0.95,
        repeatY: 3.15,
      },
      S: {
        repeatX: 1.62,
        repeatY: 3.15,
      },
      herringboneWoodura: {
        repeatX: 1.4,
        repeatY: 1.5,
      },
      herringbone: {
        repeatX: 4,
        repeatY: 4,
      },
      naduraTiles: {
        repeatX: 6.5,
        repeatY: 5.5,
      },
    }),
    []
  );
  const matParams = useMemo(
    () => ({
      wooduraProMattLaquer: {
        clearcoat: 0.1,
        clearcoatRoughness: 0.55,
        reflectivity: 0.3,
      },
      wooduraBrushedMattLaquer: {
        clearcoat: 0.4,
        clearcoatRoughness: 0.85,
        clearcoatNormal: "brushed",
        reflectivity: 0.3,
      },
      wooduraHardWaxOil: {
        clearcoat: 0.05,
        clearcoatRoughness: 0.9,
        reflectivity: 0.1,
      },
      wooduraMattLaquerOld: {
        clearcoat: 0.5,
        clearcoatRoughness: 0.75,
        reflectivity: 0.9,
      },
      parquetMattLaquer: {
        clearcoat: 0.5,
        clearcoatRoughness: 0.7,
        reflectivity: 0.5,
      },
      nadura: {
        clearcoat: 0.5,
        clearcoatRoughness: 0.8,
        reflectivity: 0.5,
        metalness: 0.3,
      },
    }),
    []
  );

  const floors = useMemo(
    () => [
      //Natural
      {
        id: "30082",
        label: "EKET 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "30085",
        label: "LYA 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "347101",
        label: "KVARNBY 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      /*{
        id: "310009",
        label: "SKOGEN 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },*/
      {
        id: "347107",
        label: "NORRLIA 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "345017",
        label: "MÖLLE 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraHardWaxOil",
      },
      {
        id: "345021",
        label: "VIKEN 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraHardWaxOil",
      },

      //Powder white
      {
        id: "30084",
        label: "VIARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "30087",
        label: "LARVIK 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "347103",
        label: "DALBY 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "345015",
        label: "BOSARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraHardWaxOil",
      },
      {
        id: "345019",
        label: "MAGNARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraHardWaxOil",
      },

      //Misty white
      {
        id: "30083",
        label: "HÖJA 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "30086",
        label: "VEJBY 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "347102",
        label: "HASSLARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "347108",
        label: "LOSHULT 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "345016",
        label: "SKARSHULT 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraHardWaxOil",
      },
      {
        id: "345020",
        label: "HITTARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraHardWaxOil",
      },

      //Earth grey
      {
        id: "346004",
        label: "BONNARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "346011",
        label: "GREVIE 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "347104",
        label: "STEHAG 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "347109",
        label: "GRYBY 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "345018",
        label: "ALLARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraHardWaxOil",
      },

      //Mineral grey
      {
        id: "346005",
        label: "EKEBORG 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "346012",
        label: "HARADAL 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "347105",
        label: "SKIVARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },

      //Terra brown
      {
        id: "346006",
        label: "TULLSTORP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "346013",
        label: "EKERED 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "347114",
        label: "GANTOFTA 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "347118",
        label: "VENESTAD 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },

      //Shadow brown
      {
        id: "346007",
        label: "LINDBY 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "346014",
        label: "HAGSTAD 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraBrushedMattLaquer",
      },
      {
        id: "347115",
        label: "TOLLARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },

      //Medium smoked
      {
        id: "347110",
        label: "STARBY 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      //Hard smoked
      {
        id: "347111",
        label: "LUGNET 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      //Honey
      {
        id: "347106",
        label: "ONSLUNDA 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "347117",
        label: "NOSABY 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },

      //Ask
      {
        id: "347112",
        label: "GULLARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "347113",
        label: "SMEDSTORP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "347119",
        label: "ESARP 3.0 XXL",
        format: "XXL",
        mtrl: "wooduraProMattLaquer",
      },

      //Valnöt
      /*{
        id: "347059",
        label: "HUMLARP 3.0 XXL",
        format: "XL",
        mtrl: "wooduraProMattLaquer",
      },
      {
        id: "347060",
        label: "VALLBY 3.0 XXL",
        format: "XL",
        mtrl: "wooduraProMattLaquer",
      },*/

      //2.0
      {
        id: "27069",
        label: "SKIVARP 2.0",
        format: "XXL",
        mtrl: "wooduraMattLaquerOld",
      },
      {
        id: "27075",
        label: "NORRLIA 2.0",
        format: "XXL",
        mtrl: "wooduraMattLaquerOld",
      },
      /*
      {
        id: "26015",
        label: "ERVALLA",
        format: "herringboneWoodura",
        mtrl: "wooduraMattLaquerOld",
      },*/
      /*{
        id: "40037",
        label: "FALUN",
        format: "herringbone",
        mtrl: "parquetMattLaquer",
      },
      {
        id: "50033",
        label: "NADURA 50033",
        format: "naduraTiles",
        mtrl: "nadura",
      },*/
    ],
    []
  );

  const { data: settings } = useQuery(
    ["settings", "en"],
    async () => await loadSettings("en"),
    { retry: false, useErrorBoundary: false }
  );
  const { data: products } = useQuery(
    ["products", "en"],
    async () => await loadProducts("en"),
    { retry: false, useErrorBoundary: false }
  );

  useEffect(() => {
    const ncsColor = parseNCS("NCS " + wallColor);
    const ncsAccColor = "5050-" + hueRotate(ncsColor.hue, 150);
    setAccentColor(ncsAccColor);
  }, [roomType, rooms, wallColor]);

  const _activeColor = colors.find((c) => c.ncs === wallColor);
  const activeColor =
    _activeColor === undefined
      ? { ncs: wallColor, rgb: ncscolor.hex("NCS " + wallColor) }
      : _activeColor;
  const activeAccentColor = {
    ncs: accentColor,
    rgb: ncscolor.hex("NCS " + accentColor),
  };

  //console.log("activeColor", activeColor, activeAccentColor);

  function onPan({ target, movement: [x, y] }) {
    if (target.tagName !== "CANVAS") return;
    startRender();
    panX.set(
      Math.min(
        Math.max(
          originPanX.current - x / (winDims.innerWidth * 0.75),
          -Math.PI / 6
        ),
        Math.PI / 6
      )
    );
    panY.set(
      Math.min(
        Math.max(
          originPanY.current + y / (winDims.innerHeight * 0.75),
          -Math.PI / 6
        ),
        Math.PI / 6
      )
    );
    update();
  }

  function onPanStart(info) {
    if (info.target.tagName !== "CANVAS") return;
    isPanning.current = true;
    animate(autoPan, 0, {
      duration: 0.5,
      onUpdate: (v) => {
        update();
      },
    });

    isManuallyStopped.current = true;
    setIsPlaying(false);
  }

  function onPanEnd({ target, movement: [x, y] }) {
    if (target.tagName !== "CANVAS") return;
    isPanning.current = false;
    debouncedStopRender();
    originPanX.current = Math.min(
      Math.max(
        originPanX.current - x / (winDims.innerWidth * 0.75),
        -Math.PI / 6
      ),
      Math.PI / 6
    );
    originPanY.current = Math.min(
      Math.max(
        originPanY.current + y / (winDims.innerHeight * 0.75),
        -Math.PI / 6
      ),
      Math.PI / 6
    );
    /*animate(panX, 0, { duration: 1 });
                                          animate(panY, 0, { duration: 1 });
                                          animate(autoPan, 1, { duration: 1 });*/
  }

  function onPinch({ target, offset: [scale, angle] }) {
    if (target.tagName !== "CANVAS") {
      return;
    }
    update();
    zoom.set(Math.min(Math.max(scale - 0.5, -0.3), 1));
    setIsZoomed(zoom.current > 0.5);
  }

  /*const throttledAnimateZoom = useMemo(
    () =>
      throttle((y) => {
        animate(zoom, Math.min(Math.max(zoom.current + y / 1000, -0.3), 1), {
          duration: 0.3,
          onUpdate: (v) => {
            invalidate();
          },
        });
      }, 50),
    [zoom]
  );*/

  function onWheel({
    target,
    velocity: [x, y],
    direction: [xdir, ydir],
    delta: [deltaX, deltaY],
    deltaMode,
    DOM_DELTA_PIXEL,
  }) {
    if (target.tagName !== "CANVAS") {
      return;
    }
    if (zoom.isAnimating) {
      zoom.stop();
    }

    const minVal = -0.3;
    const maxVal = 1;
    if (deltaMode === DOM_DELTA_PIXEL) {
      /** Touchpad **/
      zoom.set(
        Math.min(Math.max(zoom.current + deltaY / 1000, minVal), maxVal)
      );
      update();
    } else {
      /** Line or page scroll **/
      animate(
        zoom,
        Math.min(Math.max(zoom.current + (ydir * y) / 10, minVal), maxVal),
        {
          duration: 0.3,
          onUpdate: (v) => {
            update();
          },
        }
      );
    }

    setIsZoomed(zoom.current > 0.5);
  }

  const bindGestures = useGesture({
    onDrag: (state) => onPan(state),
    onDragStart: (state) => onPanStart(state),
    onDragEnd: (state) => onPanEnd(state),
    onPinch: (state) => onPinch(state),
    onWheel: (state) => onWheel(state),
  });

  const activeRoom = rooms.find((r) => r.id === roomType);
  let activeFloor = floors.find((f) => f.id === floor);
  if (activeFloor === undefined) {
    activeFloor = floors.find((f) => f.id === "30082");
  }

  const onShareClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await navigator.share({
          title: "Bjelin Virtual Showroom",
          text: "Bjelin Virtual Showroom: " + activeFloor.label,
          url: "https://bjel.in/se/art/" + activeFloor.id, //window.location.href,
        });
      } catch (err) {
        console.warn(err.message);
      }
    },
    [activeFloor.id, activeFloor.label]
  );

  return (
    <>
      <div
        style={{
          position: "absolute",
          inset: 0,
          backgroundColor: "#3e3e3e",
          color: "#fff",
          userSelect: "none",
          touchAction: "none",
        }}
        {...bindGestures()}
      >
        <EditorCanvas
          roomType={roomType}
          floor={floor}
          floors={floors}
          matParams={matParams}
          formats={formats}
          wallColor={activeColor.rgb}
          accentColor={activeAccentColor.rgb}
          camX={camX}
          camY={camY}
          camZ={camZ}
          sunIntensity={sunIntensity}
          displayPerf={perfOn}
          frameLoopOn={frameLoopOn}
          setLowPerfMode={setLowPerfMode}
          lowResMode={lowResMode}
        />
        {!hideLogo && (
          <motion.div
            style={{
              position: "absolute",
              fontSize: isSmall ? "0.75em" : "1em",
              top: "calc(2em + var(--safe-area-inset-top))",
              left: "3em",
              right: "3em",
              height: "4.5em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              filter: "drop-shadow(0 0 4px rgba(0,0,0,0.5))",
            }}
            initial={{
              opacity: 0,
              y: "70vw",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 138.16 34.24"
              style={{
                height: "-webkit-fill-available",
                maxHeight: "4vh",
                maxWidth: "28vw",
              }}
            >
              <g>
                <path
                  fill="currentColor"
                  d="M0,0H12.75c3.23,.03,5.66,.87,7.29,2.52,1.64,1.66,2.46,3.86,2.46,6.59,.03,1.4-.29,2.73-.97,4.01-.35,.61-.84,1.18-1.49,1.72-.65,.57-1.44,1.09-2.39,1.56v.09c1.79,.48,3.15,1.45,4.07,2.91,.86,1.51,1.29,3.14,1.29,4.91-.03,2.94-.92,5.26-2.66,6.96-1.73,1.79-3.89,2.68-6.48,2.68H0V0ZM4.98,4.56V14.33h7.2c1.82,0,3.16-.49,4.02-1.47,.87-.91,1.31-2.05,1.31-3.42s-.44-2.54-1.31-3.49c-.86-.89-2.2-1.35-4.02-1.38H4.98Zm0,14.33v10.23h7.69c1.85-.03,3.21-.55,4.07-1.56,.86-1,1.29-2.18,1.29-3.55s-.43-2.6-1.29-3.6c-.86-.98-2.22-1.49-4.07-1.52H4.98Z"
                />
                <path
                  fill="currentColor"
                  d="M28.87,26.83c.47,.64,1.07,1.16,1.81,1.56,.74,.48,1.6,.72,2.6,.72,1.32,.03,2.58-.44,3.78-1.42,.59-.48,1.04-1.12,1.37-1.91,.34-.76,.52-1.72,.52-2.87V0h4.98V23.41c-.06,3.42-1.14,6.06-3.24,7.92-2.09,1.91-4.51,2.88-7.28,2.91-3.85-.09-6.74-1.56-8.68-4.4l4.15-3.01Z"
                />
                <path
                  fill="currentColor"
                  d="M76.19,0h4.98V29.12h16.03v4.84h-21.01V0Z"
                />
                <path fill="currentColor" d="M101.99,0h4.98V33.96h-4.98V0Z" />
                <path
                  fill="currentColor"
                  d="M113.5,0h4.69l14.9,24.14h.09V0h4.98V33.96h-4.72l-14.88-24.14h-.09v24.14h-4.98V0Z"
                />
                <g>
                  <rect
                    fill="currentColor"
                    x="60.4"
                    y="14.7"
                    width="8.83"
                    height="4.56"
                  />
                  <polygon
                    fill="currentColor"
                    points="71.45 0 50.44 0 50.44 33.96 71.45 33.96 71.45 29.12 55.42 29.12 55.42 4.84 71.45 4.84 71.45 0"
                  />
                </g>
              </g>
            </svg>
          </motion.div>
        )}

        <motion.div
          className={"panel-button"}
          style={{
            left: "50vw",
            x: isSmall ? "-2.8em" : "-4em",
          }}
          initial={{
            opacity: 0,
            y: "-70vw",
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setRoomPanelOpen((open) => !open);
          }}
        >
          <img
            src={"/rooms/" + activeRoom.id + ".jpg"}
            alt={activeRoom.label}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              aspectRatio: "1/1",
              objectFit: "cover",
            }}
          />
          <motion.div
            className={"panel-button-inner"}
            style={{
              background: "rgba(0,0,0,0.5)",
            }}
          >
            {!isSmall && (
              <h4
                style={{
                  margin: 0,
                }}
              >
                {activeRoom.label}
              </h4>
            )}
            <div className={"panel-button-inner-box"}>Change room</div>
          </motion.div>
        </motion.div>

        <RoomSelector
          rooms={rooms}
          room={roomType}
          setRoom={setRoomType}
          isOpen={roomPanelOpen}
          close={() => setRoomPanelOpen(false)}
        />

        {/* left tools */}
        <motion.div
          className={"scene-buttons-container"}
          initial={{
            opacity: 0,
            x: "70vw",
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
        >
          {typeof navigator.share === "function" && (
            <motion.div className={"scene-button"} title="Share">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                role="img"
                viewBox="0 0 576 576"
                fill="currentColor"
                style={{ height: "2.5em" }}
                onClick={onShareClick}
              >
                <path d="M568.5 142.6l-144-135.1c-9.625-9.156-24.81-8.656-33.91 .9687c-9.125 9.625-8.688 24.81 .9687 33.91l100.1 94.56h-163.4C287.5 134.2 249.7 151 221 179.4C192 208.2 176 246.7 176 288v87.1c0 13.25 10.75 23.1 24 23.1S224 389.3 224 376V288c0-28.37 10.94-54.84 30.78-74.5C274.3 194.2 298.9 183 328 184h163.6l-100.1 94.56c-9.656 9.094-10.09 24.28-.9687 33.91c4.719 4.1 11.06 7.531 17.44 7.531c5.906 0 11.84-2.156 16.47-6.562l144-135.1C573.3 172.9 576 166.6 576 160S573.3 147.1 568.5 142.6zM360 384c-13.25 0-24 10.75-24 23.1v47.1c0 4.406-3.594 7.1-8 7.1h-272c-4.406 0-8-3.594-8-7.1V184c0-4.406 3.594-7.1 8-7.1H112c13.25 0 24-10.75 24-23.1s-10.75-23.1-24-23.1H56c-30.88 0-56 25.12-56 55.1v271.1C0 486.9 25.13 512 56 512h272c30.88 0 56-25.12 56-55.1v-47.1C384 394.8 373.3 384 360 384z" />
              </svg>
            </motion.div>
          )}
          <motion.div
            title={isZoomed ? "Zoom out" : "Zoom in"}
            className={"scene-button"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              role="img"
              viewBox="0 0 512 512"
              fill="currentColor"
              style={{ height: "2.5em" }}
              onClick={onZoomClick}
            >
              {isZoomed ? (
                <path d="M288 192H128C119.2 192 112 199.2 112 208S119.2 224 128 224h160c8.844 0 16-7.156 16-16S296.8 192 288 192zM507.3 484.7l-141.5-141.5C397 306.8 416 259.7 416 208C416 93.13 322.9 0 208 0S0 93.13 0 208S93.13 416 208 416c51.68 0 98.85-18.96 135.2-50.15l141.5 141.5C487.8 510.4 491.9 512 496 512s8.188-1.562 11.31-4.688C513.6 501.1 513.6 490.9 507.3 484.7zM208 384C110.1 384 32 305 32 208S110.1 32 208 32S384 110.1 384 208S305 384 208 384z" />
              ) : (
                <path d="M288 192H224V128c0-8.844-7.156-16-16-16S192 119.2 192 128v64H128C119.2 192 112 199.2 112 208S119.2 224 128 224h64v64c0 8.844 7.156 16 16 16S224 296.8 224 288V224h64c8.844 0 16-7.156 16-16S296.8 192 288 192zM507.3 484.7l-141.5-141.5C397 306.8 416 259.7 416 208C416 93.13 322.9 0 208 0S0 93.13 0 208S93.13 416 208 416c51.68 0 98.85-18.96 135.2-50.15l141.5 141.5C487.8 510.4 491.9 512 496 512s8.188-1.562 11.31-4.688C513.6 501.1 513.6 490.9 507.3 484.7zM208 384C110.1 384 32 305 32 208S110.1 32 208 32S384 110.1 384 208S305 384 208 384z" />
              )}
            </svg>
          </motion.div>
          <motion.div
            title={
              isPlaying ? "Start automatic rotation" : "Stop automatic rotation"
            }
            className={"scene-button"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              role="img"
              viewBox="0 0 512 512"
              fill="currentColor"
              style={{ height: "2.5em" }}
              onClick={onPlayClick}
            >
              {isPlaying ? (
                <path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM352 328c0 13.2-10.8 24-24 24h-144C170.8 352 160 341.2 160 328v-144C160 170.8 170.8 160 184 160h144C341.2 160 352 170.8 352 184V328z" />
              ) : (
                <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z" />
              )}
            </svg>
          </motion.div>
          <motion.div
            title={sunOn ? "Switch to cloudy" : "Switch to sunny"}
            className={"scene-button"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              role="img"
              viewBox="0 0 512 512"
              fill="currentColor"
              style={{ height: "2.5em" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                startRender();
                animate(
                  sunIntensity,
                  sunOn ? SUN_INTENSITY_CLOUDY : SUN_INTENSITY_SUNNY,
                  {
                    duration: 1,
                    onComplete: (v) => {
                      debouncedStopRender();
                    },
                    onUpdate: (v) => {
                      update();
                    },
                  }
                );
                setSunOn((on) => !on);
              }}
            >
              {sunOn ? (
                <path d="M120.2 154.2c4.672 4.688 10.83 7.031 16.97 7.031S149.5 158.9 154.2 154.2c9.375-9.375 9.375-24.56 0-33.93L108.9 74.97c-9.344-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94L120.2 154.2zM256 112c13.25 0 24-10.75 24-24v-64C280 10.75 269.3 0 256 0S232 10.75 232 24v64C232 101.3 242.8 112 256 112zM112 256c0-13.25-10.75-24-24-24h-64C10.75 232 0 242.8 0 256s10.75 24 24 24h64C101.3 280 112 269.3 112 256zM374.8 161.2c6.141 0 12.3-2.344 16.97-7.031l45.25-45.28c9.375-9.375 9.375-24.56 0-33.94s-24.59-9.375-33.94 0l-45.25 45.28c-9.375 9.375-9.375 24.56 0 33.93C362.5 158.9 368.7 161.2 374.8 161.2zM256 400c-13.25 0-24 10.75-24 24v64C232 501.3 242.8 512 256 512s24-10.75 24-24v-64C280 410.8 269.3 400 256 400zM120.2 357.8l-45.25 45.28c-9.375 9.375-9.375 24.56 0 33.94c4.688 4.688 10.83 7.031 16.97 7.031s12.3-2.344 16.97-7.031l45.25-45.28c9.375-9.375 9.375-24.56 0-33.93S129.6 348.4 120.2 357.8zM488 232h-64c-13.25 0-24 10.75-24 24s10.75 24 24 24h64C501.3 280 512 269.3 512 256S501.3 232 488 232zM391.8 357.8c-9.344-9.375-24.56-9.372-33.94 .0031s-9.375 24.56 0 33.93l45.25 45.28c4.672 4.688 10.83 7.031 16.97 7.031s12.28-2.344 16.97-7.031c9.375-9.375 9.375-24.56 0-33.94L391.8 357.8zM256 144C194.1 144 144 194.1 144 256c0 61.86 50.14 112 112 112s112-50.14 112-112C368 194.1 317.9 144 256 144z" />
              ) : (
                <path
                  d="M77.7,205c-0.1-2.2-0.2-4.3-0.2-6.5C77.5,128.1,134.6,71,205,71c47.3,0,88.5,25.7,110.5,63.9c12.1-8.8,26.8-12.9,42.5-12.9
	c42.2,0,76.5,33.5,76.5,76.5c0,9.7-1.8,19-5.1,27.6c46.5,9.4,81.6,50.6,81.6,99.9c0,56.3-45.7,102-102,102H115.8
	C52.4,428,1,376.6,1,313.2C1,263.2,33,220.7,77.7,205z"
                />
              )}
            </svg>
          </motion.div>
        </motion.div>
      </div>

      <FloorSelector
        floors={floors}
        floor={floor}
        setFloor={setFloor}
        products={products}
        settings={settings}
      />

      <ColorSelector
        colors={colors}
        wallColor={wallColor}
        setWallColor={setWallColor}
        setAccentColor={setAccentColor}
      />
    </>
  );
}
