import { useEffect, useRef, useState } from "react";

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

export default function useViewportSize() {
  const [windowSize, setWindowSize] = useState(getSize());
  const width = useRef(windowSize.innerHeight);
  const handleResize = () => {
    const size = getSize();
    if (size.innerWidth !== width.current) {
      width.current = size.innerWidth;
      setWindowSize(size);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
