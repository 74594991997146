import { Canvas, invalidate } from "@react-three/fiber";
import * as THREE from "three";
import { Suspense, useEffect, useState } from "react";
import Loading from "./Loading";
import { Perf } from "r3f-perf";
import EditorScene from "./EditorScene";
import { PerformanceMonitor } from "@react-three/drei";
import useViewportSize from "./useViewportSize";

export default function EditorCanvas({
  camX,
  camY,
  camZ,
  sunIntensity,
  roomType = "bedroom",
  wallColor = "#A5B986",
  accentColor = "#A5B986",
  floor = "30082",
  floors = [],
  matParams,
  formats,
  displayPerf = false,
  frameLoopOn = false,
  setLowPerfMode = (on) => {},
  lowResMode = false,
}) {
  const winDims = useViewportSize();

  useEffect(() => {
    /*console.log("frameLoopOn", frameLoopOn, "dpr", Math.max(window.devicePixelRatio));*/
    if (!frameLoopOn) {
      setDpr((dpr) => Math.max(window.devicePixelRatio, dpr));
    }
    invalidate();
  }, [frameLoopOn]);

  const maxDPR = Math.min(2, window.devicePixelRatio); //over 2 is overkill and requires too much memory on iOS
  const [dpr, setDpr] = useState(maxDPR);

  const dprSteps = [maxDPR * 0.8, maxDPR * 0.9, maxDPR];
  //let loadingPercentage = (100 * loadingProgress) / (floors.length - 1);

  return (
    <Suspense fallback={<Loading />}>
      <Canvas
        frameloop="demand"
        shadows
        gl={{
          toneMappingExposure: 2.8,
        }}
        dpr={dpr}
      >
        {displayPerf && <Perf />}
        <PerformanceMonitor
          ms={100}
          iterations={4}
          bounds={(refreshrate) => (refreshrate > 75 ? [50, 75] : [40, 60])}
          onChange={({ fps }) => {
            if (fps === 0) return;
            const factor = fps / 40;
            const minAcceptableRes = 1920;
            const physicalRes = winDims.innerWidth * window.devicePixelRatio;
            const acceptableDpr =
              physicalRes < minAcceptableRes
                ? 1
                : minAcceptableRes / physicalRes;

            const preferedDpr = dpr * factor;
            const closestDpr = dprSteps.reduce(function (prev, curr) {
              return Math.abs(curr - preferedDpr) < Math.abs(prev - preferedDpr)
                ? curr
                : prev;
            });
            const closestAcceptableDpr = dprSteps.reduce(function (prev, curr) {
              return Math.abs(curr - acceptableDpr) <
                Math.abs(prev - acceptableDpr)
                ? curr
                : prev;
            });
            /*console.log(
              "fps:",
              fps,
              "factor:",
              factor,
              "closestDpr:",
              closestDpr,
              "closestAcceptableDpr:",
              closestAcceptableDpr
            );*/
            if (!frameLoopOn) {
              setLowPerfMode(false);
              //Set to maximum quality
              /*console.log("Set to maximum quality", Math.min(window.devicePixelRatio, 2));*/
              setDpr(Math.min(window.devicePixelRatio, 2));
            } else {
              setLowPerfMode(closestDpr < closestAcceptableDpr);
              /*console.log("ClosestDPR", closestDpr, closestAcceptableDpr);*/
              setDpr(Math.max(closestDpr, closestAcceptableDpr));
            }
          }}
        ></PerformanceMonitor>
        <EditorScene
          roomType={roomType}
          floor={floor}
          floors={floors}
          matParams={matParams}
          formats={formats}
          wallColor={wallColor}
          accentColor={accentColor}
          camX={camX}
          camY={camY}
          camZ={camZ}
          sunIntensity={sunIntensity}
          displayPerf={displayPerf}
          lowResMode={lowResMode}
        />
      </Canvas>
    </Suspense>
  );
}
