/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo } from "react";
import {
  Environment,
  Lightformer,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { motion } from "framer-motion-3d";
import Color from "color";
import { SRGBColorSpace, Vector2 } from "three";
import { useTransform } from "framer-motion";
import Flame from "./Flame";

export function LivingRoom1({
  wallColor = "#111B25",
  accentColor = "#111B25",
  floor = "30082",
  floorMaterial,
  sunIntensity,
  lowResMode = false,
  ...props
}) {
  const { nodes, materials } = useGLTF("/rooms/livingroom1w.glb");

  const curtainColor = Color(accentColor).darken(0.2);

  const WoodDark = useTexture({
    map: "/textures/wood_dark" + (lowResMode ? "_low" : "") + ".jpg",
    normalMap:
      "/textures/wood_dark_normal" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_27 = useTexture({
    map: "/textures/Paint_27" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_31 = useTexture({
    map: "/textures/Paint_31" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_32 = useTexture({
    map: "/textures/Paint_32" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_33 = useTexture({
    map: "/textures/Paint_33" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_34 = useTexture({
    map: "/textures/Paint_34" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_35 = useTexture({
    map: "/textures/Paint_35" + (lowResMode ? "_low" : "") + ".jpg",
  });

  useMemo(() => {
    materials.Wall_19.color.set(wallColor);
    materials.Gold.color.set("#E7B144");
    materials.Gold.roughness = 0.145;

    materials.Wood_07.map = WoodDark.map;
    materials.Wood_07.normalMap = WoodDark.normalMap;
    materials.Wood_07.map.colorSpace = SRGBColorSpace;
    /*materials.Wood_07.map.wrapS = materials.Wood_07.map.wrapT = RepeatWrapping;
  materials.Wood_07.map.repeat.set(3, 3);*/

    materials.Paint_27.map = Paint_27.map;
    materials.Paint_31.map = Paint_31.map;
    materials.Paint_32.map = Paint_32.map;
    materials.Paint_33.map = Paint_33.map;
    materials.Paint_34.map = Paint_34.map;
    materials.Paint_35.map = Paint_35.map;
    materials.Paint_27.map.center = new Vector2(0.5, 0.5);
    //materials.Paint_27.map.rotation = -Math.PI / 2;
    materials.Paint_27.map.flipY = false;
    materials.Paint_31.map.center = new Vector2(0.5, 0.5);
    //materials.Paint_31.map.rotation = -Math.PI / 2;
    materials.Paint_31.map.flipY = false;
    materials.Paint_32.map.center = new Vector2(0.5, 0.5);
    //materials.Paint_32.map.rotation = -Math.PI / 2;
    materials.Paint_32.map.flipY = false;
    materials.Paint_33.map.center = new Vector2(0.5, 0.5);
    //materials.Paint_33.map.rotation = -Math.PI / 2;
    materials.Paint_33.map.flipY = false;
    materials.Paint_34.map.center = new Vector2(0.5, 0.5);
    //materials.Paint_34.map.rotation = -Math.PI / 2;
    materials.Paint_34.map.flipY = false;
    materials.Paint_35.map.center = new Vector2(0.5, 0.5);
    //materials.Paint_35.map.rotation = -Math.PI / 2;
    materials.Paint_35.map.flipY = false;
  }, [
    Paint_27.map,
    Paint_31.map,
    Paint_32.map,
    Paint_33.map,
    Paint_34.map,
    Paint_35.map,
    WoodDark.map,
    WoodDark.normalMap,
    materials.Gold,
    materials.Paint_27,
    materials.Paint_31,
    materials.Paint_32,
    materials.Paint_33,
    materials.Paint_34,
    materials.Paint_35,
    materials.Wall_19.color,
    materials.Wood_07,
    wallColor,
  ]);

  const sunIntensity2 = useTransform(sunIntensity, (x) => x * 1.5);

  return (
    <group {...props}>
      <Environment frames={1} resolution={256} blur={0.5}>
        {/* Ceiling */}
        <Lightformer
          intensity={0.04}
          rotation-x={Math.PI / 2}
          position={[0, 4, 0]}
          scale={[10, 10, 1]}
        />
        <Lightformer
          intensity={0.5}
          color={"#ffeed2"}
          position={[-5.5, -1, -3]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 4, 2]}
        />
        <Lightformer
          intensity={0.5}
          color={"#ffeed2"}
          position={[-5.5, -1, -0.8]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 4, 2]}
        />
      </Environment>

      <group position={[-18, 10, 5]} rotation={[0, 0, 0]}>
        <motion.spotLight
          color={"#ffeed2"}
          intensity={sunIntensity2}
          angle={0.3}
          penumbra={0}
          decay={0.8}
          castShadow
          shadow-bias={-0.005}
          shadow-radius={50}
          shadow-mapSize-width={lowResMode ? 512 : 1024}
          shadow-mapSize-height={lowResMode ? 512 : 1024}
          shadow-camera-near={3}
          shadow-camera-far={10}
        />
      </group>
      <mesh position={[-5.55, 4, -0.2]} rotation={[0, Math.PI / 2, 0]}>
        <planeGeometry args={[9, 8]} />
        <meshStandardMaterial
          color={"#ffeed2"}
          emissive={"#ffeed2"}
          emissiveIntensity={1}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube614.geometry}
        material={materials.Wall_19}
        position={[-3.679, 4.249, -1.702]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube615.geometry}
        material={materials.Wood}
        position={[-0.944, -0.059, -0.802]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube618.geometry}
        material={materials.Sofa_03}
        position={[0.36, 0.509, -3.828]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube619.geometry}
        material={materials.Sofa_03}
        position={[0.377, 1.676, -4.726]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[0.783, 0.803, 1.144]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube620.geometry}
        material={materials.Sofa_03}
        position={[0.393, 1.17, -5.066]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[1, 0.616, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube621.geometry}
        material={materials.Sofa_03}
        position={[0.398, 0.831, -3.937]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.689, 0.616, 0.468]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane097.geometry}
        material={materials.Wood}
        position={[0.28, 1.533, -4.122]}
        rotation={[0, -1.266, 0]}
        scale={1.741}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane098.geometry}
        material={materials.Bed_14}
        position={[0.265, 1.533, -3.954]}
        rotation={[Math.PI, -1.378, Math.PI]}
        scale={1.741}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube623.geometry}
        material={materials.Sofa_03}
        position={[3.727, 0.831, -1.562]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.689, 0.616, 0.468]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane099.geometry}
        material={materials.Bed_01}
        position={[0.285, 1.533, -3.672]}
        rotation={[Math.PI, -1.378, Math.PI]}
        scale={1.741}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube624.geometry}
        material={materials.Sofa_03}
        position={[2.619, 0.509, -1.545]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube625.geometry}
        material={materials.Wood_07}
        position={[-0.627, 0.556, 0.063]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane100.geometry}
        material={materials.Gold}
        position={[-4.915, 8.519, -0.751]}
        rotation={[-Math.PI, 0, -Math.PI / 2]}
        scale={0.05}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube628.geometry}
        material={materials.Paint_33}
        position={[-1.673, 5.748, -5.252]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube629.geometry}
        material={materials.Sofa}
        position={[0.317, 4.899, -5.247]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube630.geometry}
        material={materials.Paint_27}
        position={[2.309, 5.856, -5.252]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube631.geometry}
        material={materials.Paint_35}
        position={[2.162, 3.511, -5.252]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube632.geometry}
        material={materials.Paint_32}
        position={[0.171, 6.56, -5.252]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube634.geometry}
        material={materials.Paint_34}
        position={[-1.407, 3.459, -5.252]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube635.geometry}
        material={materials.Paint_31}
        position={[0.342, 4.264, -5.252]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0.809}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube626.geometry}
        material={materials.Sofa}
        position={[-5.494, 4.112, -0.787]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1, 1, 0.222]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube617.geometry}
        material={materials.Sofa}
        position={[-1.012, 1.076, 0.451]}
        rotation={[0, 1.085, 0]}
        scale={[1.245, 1.245, 1.113]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube622.geometry}
        material={materials.Pllow_05}
        position={[-0.92, 1.141, 0.375]}
        rotation={[0, Math.PI / 9, 0]}
        scale={[1.245, 1.245, 1.113]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube636.geometry}
        material={materials.Wood_08}
        position={[-0.911, 1.011, 0.388]}
        rotation={[0, Math.PI / 9, 0]}
        scale={[1.245, 1.245, 1.113]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube637.geometry}
        material={materials.Sofa}
        position={[-0.997, 1.336, 0.431]}
        rotation={[0, 0.091, 0]}
        scale={[1.245, 1.245, 1.113]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube638.geometry}
        material={materials.Bed_14}
        position={[-0.87, 1.412, 0.466]}
        rotation={[0, -0.645, 0]}
        scale={[1.245, 1.245, 1.113]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube639.geometry}
        material={materials.Back_14}
        position={[-0.889, 1.271, 0.481]}
        rotation={[0, -0.645, 0]}
        scale={[1.245, 1.245, 1.113]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder106.geometry}
        material={materials.Gold}
        position={[-0.178, 1.413, -0.179]}
      />
      <Flame position={[-0.34, 2.86, -0.45]} scale={[0.08, 0.08, 0.08]} />
      <Flame position={[0.02, 2.86, -0.39]} scale={[0.08, 0.08, 0.08]} />
      <Flame position={[-0.43, 2.6, -0.09]} scale={[0.08, 0.08, 0.08]} />
      <Flame position={[0.06, 2.61, -0.06]} scale={[0.08, 0.08, 0.08]} />
      <Flame position={[-0.21, 2.37, 0.13]} scale={[0.08, 0.08, 0.08]} />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder115.geometry}
        material={materials.Sofa}
        position={[0.068, 2.613, -0.049]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.024}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder115001.geometry}
        material={materials.Sofa}
        position={[-0.201, 2.364, 0.133]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.024}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder115002.geometry}
        material={materials.Sofa}
        position={[-0.424, 2.599, -0.087]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.024}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder115003.geometry}
        material={materials.Sofa}
        position={[-0.336, 2.855, -0.452]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.024}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder115004.geometry}
        material={materials.Sofa}
        position={[0.022, 2.849, -0.391]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.024}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube596.geometry}
        position={[-4.735, 4.403, -0.697]}
        rotation={[0, -1.571, 0]}
        scale={[1, 1.397, 4.291]}
      >
        <meshPhysicalMaterial
          color={curtainColor.hex()}
          roughness={0.8}
          transmission={0.1}
          sheen={0.5}
          sheenRoughness={0.5}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.floor.geometry}
        material={floorMaterial}
        position={[0.079, 0.05, 0.22]}
        scale={[5.5, 1, 5.5]}
      />
    </group>
  );
}

//useGLTF.preload("/rooms/livingroom1.glb");
