import { useState } from "react";
import { motion } from "framer-motion";
import useViewportSize from "./useViewportSize";
import ColorPicker from "./ColorPicker";
import ncscolor from "ncs-color";
import { hueRotate, parseNCS } from "./ncs";
import Color from "color";

export default function ColorSelector({
  colors = [],
  wallColor,
  setWallColor = (color) => {},
  accentColor,
  setAccentColor = (color) => {},
}) {
  const winDims = useViewportSize();
  const isSmall = winDims.innerWidth < 576 || winDims.innerHeight < 420;

  const [customColor, setCustomColor] = useState("3050-R");
  const [colorPanelOpen, setColorPanelOpen] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const _setColor = (color) => {
    const ncsColor = parseNCS("NCS " + color);
    const ncsAccColor = "5050-" + hueRotate(ncsColor.hue, 150);
    /*console.log("_setColor", color, ncsColor, ncsAccColor);*/
    setWallColor(color);
    setAccentColor(ncsAccColor);
  };

  const _activeColor = colors.find((c) => c.ncs === wallColor);
  const activeColor =
    _activeColor === undefined
      ? { ncs: wallColor, rgb: ncscolor.hex("NCS " + wallColor) }
      : _activeColor;

  return (
    <>
      <ColorPicker
        isOpen={colorPickerOpen}
        close={() => setColorPickerOpen(false)}
        onChange={(val) => {
          setCustomColor(val);
          _setColor(val);
          setColorPickerOpen(false);
        }}
      />
      <motion.div
        className="panel-button"
        style={{
          bottom: isSmall ? "var(--safe-area-inset-bottom)" : 0,
          left: "4px",
        }}
        animate={{
          x: colorPanelOpen ? (isSmall ? "7.5em" : "10em") : 0,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setColorPanelOpen((open) => !open);
        }}
      >
        <div
          className="panel-button-inner"
          style={{
            backgroundColor: activeColor.rgb,
            color: Color(activeColor.rgb).isLight() ? "#000" : "#fff",
          }}
        >
          {!isSmall && (
            <h4
              style={{
                margin: 0,
              }}
            >
              {activeColor.label}
            </h4>
          )}
          <div
            className="panel-button-inner-box"
            style={{
              borderColor: Color(activeColor.rgb).isLight() ? "#000" : "#fff",
            }}
          >
            Change colour
          </div>
        </div>
      </motion.div>
      <motion.div
        className="allow-scroll"
        style={{
          position: "absolute",
          top: 0,
          fontSize: isSmall ? "0.75em" : "1em",
          width: "10em",
          left: "-10em",
          height: "100vh",
          backgroundColor: "#fff",
          overflowX: "hidden",
          overflowY: "auto",
        }}
        animate={{
          x: colorPanelOpen ? "10em" : 0,
        }}
      >
        <div
          style={{
            width: "8em",
            display: "flex",
            flexDirection: "column",
            rowGap: "1em",
            margin: "1em",
          }}
        >
          {colors.map((c) => (
            <div
              key={c.ncs}
              style={{
                width: "8em",
                height: "8em",
                position: "relative",
                cursor: "pointer",
                background:
                  c.ncs === "CUSTOM"
                    ? "linear-gradient(45deg, #000 0%, " +
                      ncscolor.hex("NCS " + customColor) +
                      " 40%, " +
                      ncscolor.hex("NCS " + customColor) +
                      " 60%, #fff 100%)"
                    : c.rgb,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (c.ncs === "CUSTOM") {
                  setColorPickerOpen((open) => !open);
                }
                _setColor(c.ncs);
                setColorPanelOpen(false);
              }}
            >
              <motion.div
                style={{
                  position: "absolute",
                  inset: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "0.2em",
                  opacity: c.ncs === "CUSTOM" ? 1 : 0,
                  background: "rgba(0,0,0,0.2)",
                  color: Color(c.rgb).isLight() ? "#000" : "#fff",
                  fontSize: "0.9em",
                }}
                whileHover={{ opacity: 1 }}
              >
                <h4
                  style={{
                    margin: 0,
                    fontSize: c.ncs === "CUSTOM" ? "1.2em" : "1em",
                  }}
                >
                  {c.label}
                </h4>
                {c.ncs === "CUSTOM" ? (
                  <div
                    style={{
                      margin: "0.4em",
                      padding: "0.1em 0.4em",
                      border: isSmall ? "0px none" : "1px solid #fff",
                    }}
                  >
                    Pick colour
                  </div>
                ) : (
                  <p>NCS {c.ncs}</p>
                )}
              </motion.div>
              <motion.div
                style={{
                  position: "absolute",
                  top: "2.5em",
                  right: 0,
                  height: "0",
                  weight: "0",
                  border: "1.4rem solid #fff",
                  borderTopColor: "transparent",
                  borderBottomColor: "transparent",
                  borderLeftColor: "transparent",
                  opacity: c.ncs === wallColor ? 1 : 0,
                }}
                animate={{
                  opacity: c.ncs === wallColor ? 1 : 0,
                }}
              ></motion.div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
}
