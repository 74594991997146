import React from "react";
import Lottie from "react-lottie";
import animationData from "./animation.json";

export default function Index() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: { preserveAspectRatio: "xMidYMid meet" },

        }}
        height={200}
        width={200}
      />
    </div>
  );
}
