const ncsRe = /^(?:NCS|NCS\sS)\s(\d{2})(\d{2})-(N|R|G|B|Y)(\d{2})?(R|G|B|Y)?$/;

const percentages = Array(99)
  .fill(0)
  .map((_, i) => ("" + (i + 1)).padStart(2, "0"));
const hues = [
  "Y",
  ...percentages.map((p) => "Y" + p + "R"),
  "R",
  ...percentages.map((p) => "R" + p + "B"),
  "B",
  ...percentages.map((p) => "B" + p + "G"),

  "G",
  ...percentages.map((p) => "G" + p + "Y"),
  "N",
];

/*const blacks = ["05", "10", "20", "30", "40", "50", "60", "70", "80", "90"];
const chromas = [
  "00",
  "05",
  "10",
  "20",
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
];*/

export function parseNCS(ncs) {
  ncs = ncs.trim().toUpperCase().match(ncsRe);

  if (ncs === null) return false;

  const Sn = parseInt(ncs[1], 10);
  const Cn = parseInt(ncs[2], 10);
  const C1 = ncs[3];
  //const N = parseInt(ncs[4], 10) || 0;
  return {
    blackness: Sn,
    chroma: Cn,
    hue: C1 + (ncs[4] || "") + (ncs[5] || ""),
  };
}

export function hueRotate(hue, rotate = 0) {
  if (hue === "N") return "N";
  return hues?.[(hues.findIndex((h) => hue === h) + rotate + 400) % 400];
}
