/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo } from "react";
import { motion } from "framer-motion-3d";
import { Environment, Lightformer, useGLTF } from "@react-three/drei";
import Color from "color";

export function ShowRoom1({
  wallColor = "#F0BEA6",
  accentColor = "#F0BEA6",
  floor = "30082",
  floorMaterial,
  sunIntensity,
  lowResMode = false,
  ...props
}) {
  const { nodes, materials } = useGLTF("/rooms/showroom1.glb");

  useMemo(() => {
    materials.Wall_08.color.set(wallColor);
    const isLight = Color(wallColor).isLight();
    materials.SVGMat.color.set(isLight ? "#000000" : "#ffffff");
  }, [materials.SVGMat.color, materials.Wall_08.color, wallColor]);

  const windowX = [0, 1.1, 2.3, 3.4];
  const windowY = [0, 1.1, 2.2, 3.3, 4.4];

  return (
    <group {...props}>
      <Environment frames={1} resolution={256} blur={0.5}>
        {/* Ceiling */}
        <Lightformer
          intensity={0.04}
          rotation-x={Math.PI / 2}
          position={[0, 4, 0]}
          scale={[10, 10, 1]}
        />
        {windowX.map((x) =>
          windowY.map((y) => (
            <Lightformer
              key={x + "-" + y}
              intensity={0.5}
              color={"#ffeed2"}
              position={[-5.5, y - 1, x - 3]}
              rotation={[0, Math.PI / 2, 0]}
              scale={[1, 1, 1]}
            />
          ))
        )}
      </Environment>

      <group position={[-18, 6, -4.2]} rotation={[0, 0, 0]}>
        <motion.spotLight
          color={"#ffeed2"}
          intensity={sunIntensity}
          angle={0.45}
          penumbra={0}
          decay={0.8}
          castShadow
          shadow-bias={-0.005}
          shadow-radius={50}
          shadow-mapSize-width={lowResMode ? 512 : 1024}
          shadow-mapSize-height={lowResMode ? 512 : 1024}
          shadow-camera-near={3}
          shadow-camera-far={10}
        />
      </group>
      <mesh position={[-5.5, 4.4, 0.8]} rotation={[0, Math.PI / 2, 0]}>
        <planeGeometry args={[9.4, 8.4]} />
        <meshStandardMaterial
          color={"#ffeed2"}
          emissive={"#ffeed2"}
          emissiveIntensity={1}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube488.geometry}
        material={materials.Wall_08}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube502.geometry}
        material={materials.Wood}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube503.geometry}
        material={materials.Sofa}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube504.geometry}
        material={materials.Sofa}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube505.geometry}
        material={materials.Sofa}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube506.geometry}
        material={materials.Sofa}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube507.geometry}
        material={materials.Sofa}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube508.geometry}
        material={materials.Sofa}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube509.geometry}
        material={materials.Sofa}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.floor006.geometry}
        material={floorMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane077.geometry}
        material={materials.Wood}
      />
      <group position={[-0.5, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve.geometry}
          material={materials.SVGMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve001.geometry}
          material={materials.SVGMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve002.geometry}
          material={materials.SVGMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve003.geometry}
          material={materials.SVGMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve004.geometry}
          material={materials.SVGMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve005.geometry}
          material={materials.SVGMat}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve006.geometry}
          material={materials.SVGMat}
        />
      </group>
    </group>
  );
}

//useGLTF.preload("/rooms/showroom1.glb");
