/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo } from "react";
import {
  Environment,
  Lightformer,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import {
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Vector2,
} from "three";
import { motion } from "framer-motion-3d";
import Color from "color";

export default function Kitchen1({
  wallColor = "#A5B986",
  accentColor = "#A5B986",
  floor = "30082",
  sunIntensity,
  floorMaterial,
  lowResMode = false,
  ...props
}) {
  const { nodes, materials } = useGLTF("/rooms/kitchen1w.glb");

  const glossyColor = Color(accentColor).saturate(1.5).lighten(1);

  const Paint_21 = useTexture({
    map: "/textures/Paint_15" + (lowResMode ? "_low" : "") + ".jpg",
  });

  useMemo(() => {
    materials.Wall_08.color.set(wallColor);

    materials.brushedMetal = new MeshStandardMaterial({
      metalness: 1,
      color: "#ccc",
      roughness: 0.5,
    });
    materials.chrome = new MeshPhysicalMaterial({
      metalness: 1.5,
      color: "#ddd",
      roughness: 0,
      clearcoat: 0,
    });
    materials.glossyBlack = new MeshStandardMaterial({
      metalness: 0,
      color: "#222",
      roughness: 0,
    });
    materials.baseMaterial = new MeshStandardMaterial({
      metalness: 0,
      color: "#555",
      roughness: 1,
    });
    materials.topMaterial = new MeshPhysicalMaterial({
      metalness: 0,
      color: "#aaa",
      roughness: 0.5,
      clearcoat: 1,
      clearcoatRoughness: 0.4,
    });
    materials.leather = new MeshPhysicalMaterial({
      metalness: 0,
      color: accentColor,
      roughness: 0.75,
      clearcoat: 1,
      clearcoatRoughness: 0.5,
    });
    materials.wood = new MeshStandardMaterial({
      metalness: 0,
      color: "#863",
      roughness: 0.5,
    });
    materials.glossyRed = new MeshPhysicalMaterial({
      metalness: 0.5,
      color: glossyColor.hex(),
      roughness: 0,
      clearcoat: 1,
      clearcoatRoughness: 0.2,
    });
    materials.coffee = new MeshStandardMaterial({
      metalness: 0,
      color: "#553300",
      roughness: 0,
    });

    materials.Paint_21.map = Paint_21.map;
    materials.Paint_21.map.center = new Vector2(0.5, 0.5);
    materials.Paint_21.map.flipY = false;
  }, [Paint_21.map, accentColor, glossyColor, materials, wallColor]);

  const windowX = [0, 1.1, 2.3, 3.4];
  const windowY = [0, 1.1, 2.2, 3.3, 4.4];

  return (
    <group {...props}>
      <Environment frames={1} resolution={256} blur={0.5}>
        {/* Ceiling */}
        <Lightformer
          intensity={0.04}
          rotation-x={Math.PI / 2}
          position={[0, 4, 0]}
          scale={[10, 10, 1]}
        />
        {windowX.map((x) =>
          windowY.map((y) => (
            <Lightformer
              key={x + "-" + y}
              intensity={0.5}
              color={"#ffeed2"}
              position={[-5.5, y - 1, x - 3]}
              rotation={[0, Math.PI / 2, 0]}
              scale={[1, 1, 1]}
            />
          ))
        )}
      </Environment>

      <group position={[-20, 7, 7]} rotation={[0, 0, 0]}>
        <motion.spotLight
          color={"#ffeed2"}
          intensity={sunIntensity}
          angle={0.32}
          penumbra={0}
          decay={0.8}
          castShadow
          shadow-bias={-0.005}
          shadow-radius={50}
          shadow-mapSize-width={lowResMode ? 512 : 1024}
          shadow-mapSize-height={lowResMode ? 512 : 1024}
          shadow-camera-near={3}
          shadow-camera-far={10}
        />
      </group>
      <mesh position={[-5.15, 4.4, 0.8]} rotation={[0, Math.PI / 2, 0]}>
        <planeGeometry args={[9.4, 8.4]} />
        <meshStandardMaterial
          color={"#ffeed2"}
          emissive={"#ffeed2"}
          emissiveIntensity={1}
        />
      </mesh>

      <spotLight
        color={"#ffd799"}
        intensity={0}
        angle={Math.PI / 5}
        penumbra={0.15}
        decay={2.5}
        position={[-2.441, 7, 0.243]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={3.148}
        shadow-bias={-0.005}
        shadow-radius={5}
        shadow-mapSize-width={lowResMode ? 512 : 1024}
        shadow-mapSize-height={lowResMode ? 512 : 1024}
      />
      <spotLight
        color={"#ffd799"}
        intensity={0}
        angle={Math.PI / 5}
        penumbra={0.15}
        decay={2.5}
        position={[1.047, 7, 0.243]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={3.148}
        shadow-bias={-0.005}
        shadow-radius={5}
        shadow-mapSize-width={lowResMode ? 512 : 1024}
        shadow-mapSize-height={lowResMode ? 512 : 1024}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube447.geometry}
        material={materials.Wall_08}
        position={[-3.362, 4.396, 0.467]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube464.geometry}
        material={materials.Wood_06}
        position={[5.177, 5.171, -5.074]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.809}
      />
      <mesh
        receiveShadow
        geometry={nodes.Cube465.geometry}
        material={materials.Paint_21}
        position={[5.177, 5.171, -5.069]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube468.geometry}
        material={materials.Wood}
        position={[-0.627, -0.059, 1.367]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube469.geometry}
        material={materials.Sofa}
        position={[-5.035, 4.414, 1.373]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1, 1, 0.259]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube470.geometry}
        material={materials.Sofa}
        position={[-5.033, 3.546, 1.35]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1, 0.231, 0.259]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube471.geometry}
        material={materials.Sofa}
        position={[-5.033, 7.01, 1.35]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1, 0.231, 0.259]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube472.geometry}
        material={materials.Sofa}
        position={[-5.033, 1.915, 1.35]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1, 0.231, 0.259]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube473.geometry}
        material={materials.Sofa}
        position={[-5.033, 4.394, 3.47]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.351, 0.933, 0.259]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube474.geometry}
        material={materials.Sofa}
        position={[-5.033, 4.394, -0.783]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.351, 0.933, 0.259]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube483.geometry}
        material={materials.Sofa}
        position={[-5.033, 5.286, 1.35]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1, 0.231, 0.259]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.base.geometry}
        material={materials.baseMaterial}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.base2.geometry}
        material={materials.baseMaterial}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bowl.geometry}
        material={materials.wood}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cup.geometry}
        material={materials.glossyRed}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cup2.geometry}
        material={materials.glossyRed}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cup3.geometry}
        material={materials.glossyRed}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cuttingboard.geometry}
        material={materials.wood}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.drink2.geometry}
        material={materials.coffee}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.drink3.geometry}
        material={materials.coffee}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fan.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.536]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.faucet.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.footrest.geometry}
        material={materials.chrome}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.footrest2.geometry}
        material={materials.chrome}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.footrest3.geometry}
        material={materials.chrome}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fridge.geometry}
        material={materials.glossyRed}
        position={[-0.658, -0.05, -0.429]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fruit1.geometry}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      >
        <meshStandardMaterial color={"#673"} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fruit2.geometry}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      >
        <meshStandardMaterial color={"#940"} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fruit3.geometry}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      >
        <meshStandardMaterial color={"#f60"} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fruit4.geometry}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      >
        <meshStandardMaterial color={"#573"} />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.fruit5.geometry}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      >
        <meshPhysicalMaterial color={"#c70"} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1.geometry}
        material={materials.baseMaterial}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1001.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1002.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1003.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1004.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1005.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1006.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1007.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1008.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1009.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1010.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1011.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle1012.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle2.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.handle2001.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.knife.geometry}
        material={materials.wood}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.knifeblade.geometry}
        material={materials.chrome}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.knob.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.knob2.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.knob3.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.knob4.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.knob5.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lampshade.geometry}
        material={materials.glossyRed}
        position={[0, 0.8, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lampshade2.geometry}
        material={materials.glossyRed}
        position={[0, 0.8, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.oc1.geometry}
        material={materials.baseMaterial}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.oc2.geometry}
        material={materials.baseMaterial}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.oven.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ovenfront.geometry}
        material={materials.glossyBlack}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.panel.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.paneltop.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.paneltrim.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rings.geometry}
        material={materials.glossyBlack}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.seat.geometry}
        material={materials.leather}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.seat2.geometry}
        material={materials.leather}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.seat3.geometry}
        material={materials.leather}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sink.geometry}
        material={materials.brushedMetal}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.stool.geometry}
        material={materials.chrome}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.stool2.geometry}
        material={materials.chrome}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.stool3.geometry}
        material={materials.chrome}
        position={[-0.658, -0.095, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <group
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1911476_kitchen005"].geometry}
          material={materials.baseMaterial}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1911476_kitchen005_1"].geometry}
          material={materials.baseMaterial}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.top.geometry}
        material={materials.topMaterial}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.top2.geometry}
        material={materials.topMaterial}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.trim.geometry}
        material={materials.baseMaterial}
        position={[-0.658, -0.05, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.wire.geometry}
        material={materials.glossyBlack}
        position={[0, 0.8, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.wire2.geometry}
        material={materials.glossyBlack}
        position={[0, 0.8, -0.617]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.532}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.floor003.geometry}
        material={floorMaterial}
        position={[-0.667, 0, -0.556]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane024.geometry}
        material={materials.Wood}
        position={[-0.667, 0, -0.556]}
      />
    </group>
  );
}

//useGLTF.preload("/rooms/kitchen1.glb");
