// Originally forked from https://codesandbox.io/s/r3f-basic-demo-3izwu?file=/src/App.js:0-2775
import { AnimatePresence, motion } from "framer-motion";
import useViewportSize from "./useViewportSize";
import ImageLoader from "./ImageLoader";

export default function RoomSelector({
  isOpen = false,
  close = () => {},
  rooms = [],
  room,
  setRoom = (room) => {},
}) {
  const winDims = useViewportSize();
  const isSmall = winDims.innerWidth < 576 || winDims.innerHeight < 420;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            overflowY: "auto",
            backgroundColor: "#3e3e3e",
            color: "#fff",
            zIndex: 100,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            style={{
              position: "absolute",
              inset: 0,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "1em",
              alignItems: "center",
              justifyContent: "center",
              margin: isSmall ? "0.5em" : "5em",
              marginTop: "5vw",
              marginBottom: "5vw",
            }}
          >
            {rooms.map((r) => (
              <motion.div
                key={r.id}
                style={{
                  flex: "0 0 50vmin",
                  maxWidth: "33vh",
                  aspectRatio: "5/4",
                  position: "relative",
                  cursor: "pointer",
                  overflow: "hidden",
                  borderStyle: "solid",
                  borderWidth: "2vmin",
                  borderColor: r.id === room ? "#fff" : "#959797",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setRoom(r.id);
                  close();
                  console.log("picked room");
                }}
                whileHover={{ borderColor: "#fff" }}
              >
                <ImageLoader
                  imageUrl={"/rooms/" + r.id + ".jpg"}
                  containerStyles={{
                    display: "block",
                    position: "absolute",
                    inset: 0,
                  }}
                >
                  <img
                    src={"/rooms/" + r.id + ".jpg"}
                    alt={r.label}
                    style={{
                      display: "block",
                      position: "absolute",
                      inset: 0,
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </ImageLoader>
                <motion.div
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: "5em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "1em",
                    opacity: 1,
                    background: `linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.4) 40%,
      rgba(0, 0, 0, 0.15) 70%,
      rgba(0, 0, 0, 0.05) 90%,
      rgba(0, 0, 0, 0) 100%
    )`,
                    textShadow: "0 0 1rem rgba(0,0,0,0.2)",
                    fontSize: "1em",
                  }}
                  whileHover={{ opacity: 1 }}
                >
                  <h4>{r.label}</h4>
                </motion.div>
              </motion.div>
            ))}
          </div>
          <motion.div
            className={"close-button"}
            style={{
              position: "fixed",
              bottom: "calc(2em + var(--safe-area-inset-top))",
              right: "calc(2em + var(--safe-area-inset-right))",
              width: "3.2em",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onClick={(e) => {
              close();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              role="img"
              viewBox="0 0 320 512"
              fill="currentColor"
              style={{ height: "2.5em" }}
            >
              <path d="M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z"></path>
            </svg>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
