import { useEffect, useMemo, useState } from "react";

/**
 * Adapts standard DOM window history to work with our
 * { replace, push } interface.
 */
export const WindowHistoryAdapter = ({ children }) => {
  const [querystring, setQuerystring] = useState(window.location.search);

  const handleUpdate = () => setQuerystring(window.location.search);

  useEffect(() => {
    window.addEventListener("popstate", handleUpdate);
    return () => window.removeEventListener("popstate", handleUpdate);
  }, []);

  const adapter = useMemo(
    () => ({
      replace(location) {
        window.history.replaceState(location.state, "", location.search || "?");
        if (querystring !== location.search) {
          setQuerystring(location.search);
        }
      },
      push(location) {
        window.history.pushState(location.state, "", location.search || "?");
        if (querystring !== location.search) {
          //console.log("push:", location.search);
          setQuerystring(location.search);
        }
      },
      get location() {
        return window.location;
      },
    }),
    [querystring]
  );

  //console.log("querystring:", querystring);

  return children(adapter);
};
