import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import Loading from "./Loading";

function ImageLoader({
  className,
  containerClassName,
  styles,
  containerStyles,
  label,
  imageUrl,
  children,
}) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const loader = document.createElement("img");
    if (!loader.src) {
      loader.onload = () => {
        setImageLoaded(true);
      };
      loader.src = imageUrl;
    }
    return () => {
      loader.onload = null;
    };
  }, [imageUrl]);

  return (
    <div style={containerStyles} className={containerClassName}>
      <AnimatePresence initial={false}>
        {!imageLoaded && (
          <motion.div
            className={className}
            role="img"
            aria-label={label}
            style={{
              ...styles,
              display: "block",
              position: "absolute",
              zIndex: 1,
              inset: 0,
              opacity: 1,
              background: "#333",
              padding: "3em",
            }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 100,
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </div>
  );
}

ImageLoader.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholderImageUrl: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ImageLoader.defaultProps = {
  disabled: false,
};

export default ImageLoader;
