import Bedroom2 from "./Bedroom2";
import { LivingRoom1 } from "./Livingroom1";
import Kitchen1 from "./Kitchen1";
import { ChildRoom1 } from "./Childroom1";
import { Office1 } from "./Office1";
import { ShowRoom1 } from "./Showroom1";

export default function RoomCommon({
  roomType = "bedroom",
  wallColor = "#A5B986",
  accentColor = "#A5B986",
  floor = "30082",
  floorMaterial,
  sunIntensity,
  lowResMode = false,
}) {
  return (
    <>
      <Bedroom2
        floor={floor}
        floorMaterial={floorMaterial}
        wallColor={wallColor}
        accentColor={accentColor}
        sunIntensity={sunIntensity}
        lowResMode={lowResMode}
        position={[0, -0.8, 0]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={0.3}
        visible={roomType === "bedroom"}
      />
      <LivingRoom1
        floor={floor}
        floorMaterial={floorMaterial}
        wallColor={wallColor}
        accentColor={accentColor}
        sunIntensity={sunIntensity}
        lowResMode={lowResMode}
        position={[0, -0.8, 0]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={0.3}
        visible={roomType === "livingroom"}
      />
      <Kitchen1
        floor={floor}
        floorMaterial={floorMaterial}
        wallColor={wallColor}
        accentColor={accentColor}
        sunIntensity={sunIntensity}
        lowResMode={lowResMode}
        position={[0, -0.8, 0]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={0.3}
        visible={roomType === "kitchen"}
      />
      <ChildRoom1
        floor={floor}
        floorMaterial={floorMaterial}
        wallColor={wallColor}
        accentColor={accentColor}
        sunIntensity={sunIntensity}
        lowResMode={lowResMode}
        position={[0, -0.8, 0]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={0.3}
        visible={roomType === "childroom"}
      />
      <Office1
        floor={floor}
        floorMaterial={floorMaterial}
        wallColor={wallColor}
        accentColor={accentColor}
        sunIntensity={sunIntensity}
        lowResMode={lowResMode}
        position={[0, -0.8, 0]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={0.3}
        visible={roomType === "office"}
      />
      <ShowRoom1
        floor={floor}
        floorMaterial={floorMaterial}
        wallColor={wallColor}
        accentColor={accentColor}
        sunIntensity={sunIntensity}
        lowResMode={lowResMode}
        position={[0, -0.8, 0]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={0.3}
        visible={roomType === "showroom"}
      />
    </>
  );
}
