/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo } from "react";
import { motion, useTime } from "framer-motion-3d";
import {
  Environment,
  Lightformer,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { MeshPhysicalMaterial, SRGBColorSpace, Vector2 } from "three";
import { useTransform } from "framer-motion";
import Color from "color";
import { useFrame } from "@react-three/fiber";

export function Office1({
  wallColor = "#111B25",
  accentColor = "#111B25",
  floor = "30082",
  floorMaterial,
  sunIntensity,
  lowResMode = false,
  ...props
}) {
  const { nodes, materials } = useGLTF("/rooms/office1w.glb");

  const time = useTime();

  const moonLampColor = useTransform([time], ([t]) =>
    Color.hsl((t * 8) % 360, 100, 50).hex()
  );
  const moonLampIntensity = useTransform(
    [sunIntensity],
    ([i]) => 0.3 * (9 - i)
  );
  const ambientIntensity = useTransform([moonLampIntensity], ([i]) => 0.3 * i);

  const moonMaterial = useMemo(
    () =>
      new MeshPhysicalMaterial({
        color: "#fff",
        emissive: "#000",
        emissiveIntensity: 0.5,
        roughness: 0,
      }),
    []
  );

  useFrame(() => {
    if (moonLampColor.get()) {
      moonMaterial.color.set(moonLampColor.get());
      moonMaterial.emissive.set(moonLampColor.get());
    }
  });

  const WoodDark = useTexture({
    map: "/textures/wood_dark" + (lowResMode ? "_low" : "") + ".jpg",
    normalMap:
      "/textures/wood_dark_normal" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Monstera = useTexture({
    map: "/textures/Monstera" + (lowResMode ? "_low" : "") + ".jpg",
    normalMap:
      "/textures/Monstera_normal" + (lowResMode ? "_low" : "") + ".jpg",
    alphaMap: "/textures/Monstera_alpha" + (lowResMode ? "_low" : "") + ".png",
  });
  const Monstera_2 = useTexture({
    map: "/textures/Monstera_2" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_19 = useTexture({
    map: "/textures/Paint_19" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_20 = useTexture({
    map: "/textures/Paint_20" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Paint_21 = useTexture({
    map: "/textures/Paint_21" + (lowResMode ? "_low" : "") + ".jpg",
  });

  useMemo(() => {
    materials.Wall_01.color.set(wallColor);
    materials.Wood_07.color.set("#624037");
    materials.Wood_07.map = WoodDark.map;
    materials.Wood_07.normalMap = WoodDark.normalMap;
    materials.Wood_07.map.colorSpace = SRGBColorSpace;
    /*materials.Wood_07.map.wrapS = materials.Wood_07.map.wrapT = RepeatWrapping;
  materials.Wood_07.map.repeat.set(3, 3);
  materials.Wood_07.normalMap.wrapS = materials.Wood_07.map.wrapT =
    RepeatWrapping;
  materials.Wood_07.normalMap.repeat.set(3, 3);*/

    materials.Monstera.map = Monstera.map;
    materials.Monstera.alphaMap = Monstera.alphaMap;
    materials.Monstera.normalMap = Monstera.normalMap;
    materials.Monstera_02.map = Monstera_2.map;
    materials["Material.018"].color.set("#3F3D3E");
    materials.Paint_26.map = Paint_19.map;
    materials.Paint_25.map = Paint_20.map;
    materials.Paint_24.map = Paint_21.map;
    materials.Paint_24.map.center = new Vector2(0.5, 0.5);
    materials.Paint_24.map.flipY = false;
    materials.Paint_25.map.center = new Vector2(0.5, 0.5);
    materials.Paint_25.map.flipY = false;
    materials.Paint_26.map.center = new Vector2(0.5, 0.5);
    materials.Paint_26.map.flipY = false;
  }, [
    Monstera.alphaMap,
    Monstera.map,
    Monstera.normalMap,
    Monstera_2.map,
    Paint_19.map,
    Paint_20.map,
    Paint_21.map,
    WoodDark.map,
    WoodDark.normalMap,
    materials,
    wallColor,
  ]);

  return (
    <group {...props}>
      <Environment frames={1} resolution={256} blur={0.5}>
        {/* Ceiling */}
        <Lightformer
          intensity={0.04}
          rotation-x={Math.PI / 2}
          position={[0, 4, 0]}
          scale={[10, 10, 1]}
        />
        <Lightformer
          intensity={0.5}
          color={"#ffeed2"}
          position={[-5.5, -1, -3]}
          rotation={[0, 0, 0]}
          scale={[1, 4, 2]}
        />
        <Lightformer
          intensity={0.5}
          color={"#ffeed2"}
          position={[-5.5, -1, -0.8]}
          rotation={[0, 0, 0]}
          scale={[1, 4, 2]}
        />
      </Environment>

      <group position={[-4, 6.8, -15]} rotation={[0, 0, 0]}>
        <motion.spotLight
          color={"#ffffff"}
          intensity={sunIntensity}
          angle={0.3}
          penumbra={0}
          decay={0.8}
          castShadow
          shadow-bias={-0.005}
          shadow-radius={50}
          shadow-mapSize-width={lowResMode ? 512 : 1024}
          shadow-mapSize-height={lowResMode ? 512 : 1024}
        />
      </group>
      <mesh position={[0, 4, -5.9]} rotation={[0, 0, 0]}>
        <planeGeometry args={[8, 5]} />
        <meshStandardMaterial
          color={"#ffeed2"}
          emissive={"#ffeed2"}
          emissiveIntensity={0.5}
        />
      </mesh>
      <motion.pointLight
        intensity={moonLampIntensity}
        color={moonLampColor}
        decay={2}
        position={[0.314, 2.116, 1.775]}
        rotation={[-Math.PI / 2, 0, 0]}
        castShadow
        shadow-bias={-0.005}
        shadow-radius={100}
        shadow-camera-near={0.16}
        shadow-camera-far={5}
        shadow-mapSize-width={lowResMode ? 512 : 2048}
        shadow-mapSize-height={lowResMode ? 512 : 2048}
      />
      <motion.pointLight
        intensity={ambientIntensity}
        color={moonLampColor}
        decay={0.2}
        position={[0.314, 7, -1.775]}
        rotation={[-Math.PI / 2, 0, 0]}
        castShadow
        shadow-bias={-0.005}
        shadow-radius={100}
        shadow-camera-near={1}
        shadow-camera-far={15}
        shadow-mapSize-width={lowResMode ? 512 : 2048}
        shadow-mapSize-height={lowResMode ? 512 : 2048}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube457.geometry}
        material={materials.Wood_04}
        position={[-0.003, -0.122, -1.19]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube460.geometry}
        material={materials.Paint_24}
        position={[-5.185, 4.955, -0.155]}
        scale={0.782}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube461.geometry}
        material={materials.Sofa}
        position={[-5.179, 4.955, -0.155]}
        scale={0.782}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube462.geometry}
        material={materials.Paint_26}
        position={[-5.185, 4.955, 2.183]}
        scale={0.782}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube463.geometry}
        material={materials.Sofa}
        position={[-5.179, 4.955, 2.183]}
        scale={0.782}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube466.geometry}
        material={materials.Paint_25}
        position={[-5.185, 4.955, -2.504]}
        scale={0.782}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube467.geometry}
        material={materials.Sofa}
        position={[-5.179, 4.955, -2.504]}
        scale={0.782}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube456.geometry}
        material={materials.frame}
        position={[0.365, 4.423, -5.616]}
        scale={[1.286, 1, 0.432]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube476.geometry}
        material={materials.Wood_05}
        position={[-1.76, 5.045, -5.574]}
        rotation={[-2.264, 0, Math.PI]}
        scale={[-2.154, -0.012, -0.12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube477.geometry}
        material={materials.Wood_05}
        position={[2.49, 5.524, -5.574]}
        rotation={[-2.264, 0, Math.PI]}
        scale={[-2.154, -0.012, -0.12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube478.geometry}
        material={materials.Wall_01}
        position={[0.332, 3.943, -5.702]}
        rotation={[0, 0, -Math.PI]}
        scale={[-1, -1, -0.013]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.floor004.geometry}
        material={floorMaterial}
        position={[0.079, 0.05, -0.105]}
        scale={[5.5, 1, 5.5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Moon_lamp_stand.geometry}
        material={materials.Wood_06}
        position={[0.287, -1.634, 1.022]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_sphere.geometry}
        material={moonMaterial}
        position={[0.311, 2.12, 1.77]}
        scale={0.501}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.table001.geometry}
        material={materials.Wood_07}
        position={[0.336, 1.972, -4.302]}
        scale={1.464}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Drawers001.geometry}
          material={materials.Wood_07}
          position={[-1.234, 0.001, 0.665]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.579, 0.946, 0.105]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Roundcube003.geometry}
            material={nodes.Roundcube003.material}
            position={[0, 0.025, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[1.726, 9.507, 1.057]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Roundcube004.geometry}
            material={nodes.Roundcube004.material}
            position={[2.072, 0.025, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[1.726, 9.507, 1.057]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Roundcube005.geometry}
            material={nodes.Roundcube005.material}
            position={[4.183, 0.025, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[1.726, 9.507, 1.057]}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.table_legs001.geometry}
          material={materials["steel.001"]}
          position={[1.694, -0.729, 0.524]}
          scale={0.06}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sofa002.geometry}
        material={materials["Material.018"]}
        position={[-4.15, 0.98, 1.626]}
        scale={1.242}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sofa_legs001.geometry}
          material={materials["steel.001"]}
          position={[1.009, -0.661, 2.283]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sofa_legs002.geometry}
          material={materials["steel.001"]}
          position={[-0.57, -0.661, 2.283]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sofa_legs003.geometry}
          material={materials["steel.001"]}
          position={[-0.57, -0.661, -2.272]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sofa_legs004.geometry}
          material={materials["steel.001"]}
          position={[1.009, -0.661, -2.272]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sofa003.geometry}
          material={materials["Material.018"]}
          position={[0.412, 0.19, 1.181]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Chair001.geometry}
        material={materials["Material.018"]}
        position={[0.107, 1.923, -1.401]}
        scale={1.426}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arm_rest001.geometry}
          material={materials["steel.001"]}
          position={[0.706, -0.083, -0.294]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Arm_rest002.geometry}
          material={materials["steel.001"]}
          position={[-0.714, -0.083, -0.294]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_cushion001.geometry}
          material={materials["Material.022"]}
          position={[0.706, 0.119, -0.316]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_cushion002.geometry}
          material={materials["Material.022"]}
          position={[-0.714, 0.119, -0.316]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Chair_Legs001.geometry}
          material={materials["steel.001"]}
          position={[0, -0.911, -0.272]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mac001.geometry}
        material={materials["steel.001"]}
        position={[0.291, 2.997, -4.315]}
        scale={1.464}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mac_monitor002.geometry}
          material={materials["Black.003"]}
          position={[-0.002, 0.226, 0.044]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mac_monitor003.geometry}
          material={materials["steel.001"]}
          position={[-0.002, -0.345, 0.044]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Monitor_stand001.geometry}
          material={materials["steel.001"]}
          position={[0, -0.379, -0.098]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane066.geometry}
        material={materials.Wood_07}
        position={[0.302, 1.244, 1.772]}
        scale={1.464}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane068.geometry}
          material={materials["steel.001"]}
          position={[0.014, -0.684, 0.011]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lampstrings001.geometry}
        material={materials["Lampstrings_material.001"]}
        position={[0.336, -0.427, -0.017]}
        scale={1.464}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lamp_B2001.geometry}
          material={materials["Lampstrings_material.001"]}
          position={[-0.049, 0.08, 0]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle006.geometry}
        material={materials["steel.001"]}
        position={[-1.876, 2.349, -4.554]}
        scale={1.464}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle007.geometry}
        material={materials["steel.001"]}
        position={[-1.934, 2.754, -4.551]}
        scale={1.464}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere017.geometry}
        material={materials["Material.045"]}
        position={[-1.745, 2.959, -4.572]}
        scale={2.92}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tutacaq001.geometry}
        material={nodes.Tutacaq001.material}
        position={[1.794, 1.972, -3.73]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.048, 0.048, 0.008]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.tutacaq001.geometry}
        material={materials["steel.001"]}
        position={[1.899, 2.305, -3.789]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={7.881}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane069.geometry}
          material={materials["Black.003"]}
          position={[0.02, -0.003, 0]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0.896}
        />
      </mesh>
      <group
        position={[0.285, 2.181, -3.748]}
        rotation={[0.001, 0, 0]}
        scale={0.22}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane141.geometry}
          material={materials["PCBody.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane141_1.geometry}
          material={materials["PortWires.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane141_2.geometry}
          material={materials["Port.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane141_3.geometry}
          material={materials["KeyboardKeys.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane141_4.geometry}
          material={materials["KeyboardLine.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane141_5.geometry}
          material={materials["On/Off.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane141_6.geometry}
          material={materials["Side.002"]}
        />
      </group>
      <group
        position={[0.966, 2.185, -3.738]}
        rotation={[Math.PI / 2, 0, -1.71]}
        scale={0.142}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane142.geometry}
          material={materials["steel.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane142_1.geometry}
          material={materials["Port.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane142_2.geometry}
          material={materials["Logos.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane142_3.geometry}
          material={materials["On/Off.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane142_4.geometry}
          material={materials["Side.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane142_5.geometry}
          material={materials["Lens.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane142_6.geometry}
          material={materials["MouseTop.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder182.geometry}
        material={materials.Wood_07}
        position={[4.276, 1.159, -4.265]}
        rotation={[0, -1.511, 0]}
        scale={[0.118, 0.37, 0.118]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["03005"].geometry}
        material={materials.Monstera}
        position={[4.345, 3.75, -4.407]}
        rotation={[Math.PI / 2, 0, 1.511]}
        scale={0.035}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object001002.geometry}
        material={materials.Monstera_02}
        position={[4.295, 3.159, -4.323]}
        rotation={[Math.PI / 2, 0, 1.511]}
        scale={0.035}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder183.geometry}
        material={materials.Wood_07}
        position={[4.288, 1.868, -4.266]}
        rotation={[0, -1.511, 0]}
        scale={0.526}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.stereo_plan001.geometry}
        material={materials.Wood_07}
        position={[4.826, 0.545, 1.607]}
        scale={1.251}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane057.geometry}
          material={materials.Wood_07}
          position={[0.393, -0.288, 1.905]}
          scale={0.144}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Player004.geometry}
        material={materials["steel.001"]}
        position={[4.826, 0.836, 1.607]}
        scale={[0.608, 1.251, 0.852]}
      >
        <group position={[0, 0.072, 0]} scale={[2.057, 1, 1.468]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle017.geometry}
            material={materials["Black.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Circle017_1.geometry}
            material={materials["Material.019"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Player_button001.geometry}
          material={materials["steel.001"]}
          position={[-0.571, 0.1, 0.624]}
          scale={[2.057, 1, 1.468]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Player_Button002.geometry}
          material={materials["Black.002"]}
          position={[-0.769, 0.054, 0.86]}
          scale={[2.057, 1, 1.468]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Player_Button003.geometry}
          material={materials["Black.002"]}
          position={[-0.769, 0.054, 0.713]}
          scale={[2.057, 1, 1.468]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Player_legs001.geometry}
          material={nodes.Player_legs001.material}
          position={[0.732, -0.068, 0.811]}
          scale={[0.15, 0.073, 0.107]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Player_Line001.geometry}
          material={materials["steel.001"]}
          position={[-0.213, 0.1, 0.718]}
          scale={[2.057, 1, 1.468]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Player005.geometry}
          material={nodes.Player005.material}
          position={[0, 0.055, 0]}
          scale={[0.698, 0.339, 0.498]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Player006.geometry}
          material={materials["steel.001"]}
          position={[0.291, 0.057, 0.736]}
          scale={[2.057, 1, 1.468]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Player007.geometry}
          material={materials["steel.001"]}
          position={[0.378, 0.1, 0.736]}
          scale={[2.057, 1, 1.468]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Speakers001.geometry}
        material={materials["Black.003"]}
        position={[4.83, 1.723, 4.782]}
        scale={[1.251, 1.251, 1.125]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane059.geometry}
        material={materials["Black.003"]}
        position={[4.855, 1.137, -0.919]}
        rotation={[1.063, 0, 0]}
        scale={1.251}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane060.geometry}
          material={materials["Black.003"]}
          position={[0, 0.005, 0.003]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane062.geometry}
          material={materials["Black.003"]}
          position={[0, 0.011, 0.008]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane063.geometry}
          material={materials["Black.003"]}
          position={[0, 0.111, 0.122]}
          rotation={[-0.238, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane065.geometry}
          material={materials["Black.003"]}
          position={[0, 0.258, 0.29]}
          rotation={[-0.484, 0, 0]}
        />
      </mesh>
    </group>
  );
}

//useGLTF.preload("/rooms/office1.glb");
