import { useTranslation } from "react-i18next";
import { defaultNS } from "./i18next";

const options = { useSuspense: true };

export function useI18n() {
  return useTranslation(defaultNS, options).i18n;
}

export function useBjelinTranslations() {
  return useTranslation(defaultNS, options).t;
}

export function useProductTranslations() {
  return useTranslation("products", options).t;
}

export function useSpecsTranslations() {
  const ts = useTranslation("specifications", options).t;
  return (spec, value) =>
    ts(spec + (value ? "." + value : ""), /*default value:*/ value);
}

export function useUnitTranslations() {
  const ts = useTranslation("specifications", options).t;
  return (unit) => ts("unit." + unit, /*default value:*/ unit);
}

export function useCountriesTranslations() {
  return useTranslation("countries", options).t;
}

export function useTagsTranslations() {
  return useTranslation("tags", options).t;
}
