import { useAnimationFrame, useMotionValue } from "framer-motion";
import { useRef } from "react";
import { invalidate } from "@react-three/fiber";

export function usePlayingTime(isPlaying = true) {
  const playingTime = useMotionValue(0);
  const prevTime = useRef(0);
  useAnimationFrame((t) => {
    if (isPlaying) {
      playingTime.set(playingTime.get() + t - prevTime.current);
      invalidate();
    }
    prevTime.current = t;
  });
  return playingTime;
}
