/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo } from "react";
import { motion } from "framer-motion-3d";
import {
  Environment,
  Lightformer,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { Vector2 } from "three";
import Color from "color";

export function ChildRoom1({
  wallColor = "#F0BEA6",
  accentColor = "#F0BEA6",
  floor = "30082",
  floorMaterial,
  sunIntensity,
  lowResMode = false,
  ...props
}) {
  const { nodes, materials } = useGLTF("/rooms/childroom1w.glb");

  const canopyColor = Color(wallColor)
    .mix(Color(accentColor), 0.8)
    .saturate(0.8)
    .lighten(1.1);
  const canopyColor2 = Color(accentColor).lighten(0.9);

  /*const Child_room_16 = useTexture({
    map: "/textures/Child_room_16" + (lowResMode ? "_low" : "") + ".jpg",
  });*/
  const Child_room_17 = useTexture({
    map:
      "/textures/Child_room_17" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Child_room_18 = useTexture({
    map:
      "/textures/Child_room_18" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Child_room_19 = useTexture({
    map:
      "/textures/Child_room_19" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Child_room_20 = useTexture({
    map:
      "/textures/Child_room_20" + (lowResMode ? "_low" : "") + ".jpg",
  });
  const Child_room_21 = useTexture({
    map:
      "/textures/Child_room_21" + (lowResMode ? "_low" : "") + ".jpg",
  });

  useMemo(() => {
    materials.Wall_05.color.set(wallColor);
    materials.Canopy_05.color.set(canopyColor.hex());
    materials.Canopy_06.color.set(canopyColor2.hex());
    materials.Canopy_01.map = Child_room_21.map;
    //materials.Canopy_05.map = Child_room_16.map;
    //materials.Canopy_06.map = Child_room_15.map;
    materials.Paint_07.map = Child_room_20.map;
    materials.Paint_08.map = Child_room_19.map;
    materials.Paint_09.map = Child_room_17.map;
    materials.Paint_10.map = Child_room_18.map;
    materials.Paint_07.map.center = new Vector2(0.5, 0.5);
    materials.Paint_07.map.flipY = false;
    materials.Paint_08.map.center = new Vector2(0.5, 0.5);
    materials.Paint_08.map.flipY = false;
    materials.Paint_09.map.center = new Vector2(0.5, 0.5);
    materials.Paint_09.map.flipY = false;
    materials.Paint_10.map.center = new Vector2(0.5, 0.5);
    materials.Paint_10.map.flipY = false;
  }, [
    Child_room_17.map,
    Child_room_18.map,
    Child_room_19.map,
    Child_room_20.map,
    Child_room_21.map,
    canopyColor,
    canopyColor2,
    materials.Canopy_01,
    materials.Canopy_05.color,
    materials.Canopy_06.color,
    materials.Paint_07,
    materials.Paint_08,
    materials.Paint_09,
    materials.Paint_10,
    materials.Wall_05.color,
    wallColor,
  ]);

  return (
    <group {...props}>
      <Environment frames={1} resolution={256} blur={0.5}>
        {/* Ceiling */}
        <Lightformer
          intensity={0.04}
          rotation-x={Math.PI / 2}
          position={[0, 4, 0]}
          scale={[10, 10, 1]}
        />
        <Lightformer
          intensity={0.5}
          color={"#ffeed2"}
          position={[-5.5, -1, -3]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 4, 2]}
        />
        <Lightformer
          intensity={0.5}
          color={"#ffeed2"}
          position={[-5.5, -1, -0.8]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[1, 4, 2]}
        />
      </Environment>

      <group position={[-10, 6.8, 4.5]} rotation={[0, 0, 0]}>
        <motion.spotLight
          color={"#ffeed2"}
          intensity={sunIntensity}
          angle={0.5}
          penumbra={0}
          decay={0.8}
          castShadow
          shadow-bias={-0.005}
          shadow-radius={50}
          shadow-mapSize-width={lowResMode ? 512 : 1024}
          shadow-mapSize-height={lowResMode ? 512 : 1024}
        />
      </group>
      <mesh position={[-5.0, 4, 0.5]} rotation={[0, Math.PI / 2, 0]}>
        <planeGeometry args={[7, 5]} />
        <meshStandardMaterial
          color={"#ffeed2"}
          emissive={"#ffeed2"}
          emissiveIntensity={1}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001015.geometry}
        material={materials.Canopy_03}
        position={[0.78, 0.991, -2.424]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001016.geometry}
        material={materials.Canopy_01}
        position={[1.136, 0.238, -1.757]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001017.geometry}
        material={materials.Canopy_02}
        position={[1.136, 0.371, -1.757]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001018.geometry}
        material={materials.Canopy_03}
        position={[1.136, 0.497, -1.757]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001019.geometry}
        material={materials.Canopy_04}
        position={[1.136, 0.63, -1.757]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001020.geometry}
        material={materials.Canopy_04}
        position={[0.823, 0.964, -1.304]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001021.geometry}
        material={materials.Canopy_05}
        position={[2.1, 0.892, -1.381]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001022.geometry}
        material={materials.Canopy_06}
        position={[0.615, 1.123, -1.838]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001023.geometry}
        material={materials.Canopy_05}
        position={[1.205, 1.917, -2.267]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube479.geometry}
        material={materials.Sofa}
        position={[-4.827, 4.088, 0.916]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1, 1, 0.432]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube480.geometry}
        material={materials.Sofa}
        position={[-3.772, 0.852, 1.156]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube481.geometry}
        material={materials.Sofa}
        position={[-4.12, 3.383, -3.36]}
        scale={[1, 1.29, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001024.geometry}
        material={materials.Canopy_02}
        position={[-4.153, 1.589, 1.268]}
        rotation={[Math.PI / 2, 0, 1.565]}
        scale={[0.035, 0.027, 0.03]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube482.geometry}
        material={materials.Sofa}
        position={[-3.362, 6.203, -3.999]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.428, 0.495, 4.739]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube484.geometry}
        material={materials.Sofa}
        position={[-3.362, 3.176, -3.999]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.428, 0.495, 4.739]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube485.geometry}
        material={materials.Sofa}
        position={[-3.362, 0.533, -3.36]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.428, 0.495, 4.739]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube486.geometry}
        material={materials.Sofa}
        position={[-3.362, 6.203, -2.718]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.428, 0.495, 4.739]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube487.geometry}
        material={materials.Sofa}
        position={[-3.362, 3.176, -2.719]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.428, 0.495, 4.739]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001025.geometry}
        material={materials.Canopy_06}
        position={[-4.196, 2.093, -1.577]}
        rotation={[Math.PI / 2, 0, -0.883]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001026.geometry}
        material={materials.Canopy_04}
        position={[-4.185, 2.079, -0.844]}
        rotation={[Math.PI / 2, 0, -0.883]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001027.geometry}
        material={materials.Canopy_03}
        position={[-4.201, 2.079, -0.137]}
        rotation={[Math.PI / 2, 0, -0.883]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder184.geometry}
        material={materials.Wood_02}
        position={[-0.6, 0.176, 2.2]}
        scale={[0.884, 0.302, 0.884]}
      />
      <mesh
        receiveShadow
        geometry={nodes.Plane056.geometry}
        material={materials.Paint_08}
        position={[-0.395, 5.277, -4.59]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.746}
      />
      <mesh
        receiveShadow
        geometry={nodes.Plane070.geometry}
        material={materials.Paint_07}
        position={[1.335, 5.277, -4.59]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.746}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane071.geometry}
        material={materials.Sofa}
        position={[0.47, 4.44, -4.583]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.746}
      />
      <mesh
        receiveShadow
        geometry={nodes.Plane074.geometry}
        material={materials.Paint_10}
        position={[-0.395, 3.603, -4.59]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.746}
      />
      <mesh
        receiveShadow
        geometry={nodes.Plane075.geometry}
        material={materials.Paint_09}
        position={[1.335, 3.603, -4.59]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.746}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere018.geometry}
        material={materials.Glass}
        position={[-3.277, 3.896, -3.362]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.084, 0.076, 0.084]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Shape001028.geometry}
        material={materials.Canopy_03}
        position={[1.144, 4.069, -1.723]}
        rotation={[Math.PI / 2, 0, -0.007]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube489.geometry}
        material={materials.Sofa}
        position={[-4.833, 4.078, 0.914]}
        rotation={[-Math.PI / 2, -0.694, -Math.PI / 2]}
        scale={[-2.154, -0.012, -0.12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube490.geometry}
        material={materials.Wood}
        position={[-0.376, -0.077, -0.14]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={[-1, -1.22, -0.021]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube491.geometry}
        material={materials.Wall_05}
        position={[-0.376, 3.391, -4.706]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[-1, -1.22, -0.021]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube492.geometry}
        material={materials.Wall_05}
        position={[-4.966, 3.737, 0.317]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={[-1, -1.22, -0.021]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane076.geometry}
        material={materials.Wood}
        position={[-0.46, 0, -0.091]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.floor005.geometry}
        material={floorMaterial}
        position={[-0.46, 0, -0.091]}
      />
    </group>
  );
}

//useGLTF.preload("/rooms/childroom1.glb");
