import {
  Bloom,
  EffectComposer,
  ToneMapping,
} from "@react-three/postprocessing";
import { BakeShadows, SoftShadows } from "@react-three/drei";
import { BlendFunction } from "postprocessing";
import { MotionCamera } from "./MotionCamera";

import RoomCommon from "./RoomCommon";
import { useTransform } from "framer-motion";
import { motion } from "framer-motion-3d";
import { Perf } from "r3f-perf";
import { useEffect, useRef, useState } from "react";
import {
  MeshPhysicalMaterial,
  RepeatWrapping,
  SRGBColorSpace,
  TextureLoader,
} from "three";
import { invalidate, useFrame } from "@react-three/fiber";

const loader = new TextureLoader();

async function loadTexture(
  floor,
  repeatX,
  repeatY,
  material,
  type = "color",
  lowResMode = false,
  overridePath
) {
  const mapName =
    type === "normal"
      ? "normalMap"
      : type === "roughness"
      ? "roughnessMap"
      : type === "clearcoatNormal"
      ? "clearcoatNormalMap"
      : "map";
  return loader
    .loadAsync(
      "/floors/" +
        (!!overridePath ? overridePath : floor.id) +
        "/" +
        (type === "clearcoatNormal" && !overridePath ? "normal" : type) +
        (lowResMode ? "_low" : "") +
        ".jpg"
    )
    .then((result) => {
      material[mapName] = result;
      material[mapName].colorSpace = SRGBColorSpace;
      material[mapName].wrapS = material[mapName].wrapT = RepeatWrapping;
      material[mapName].repeat.set(repeatX, repeatY);
    });
}

const emptyMaterial = new MeshPhysicalMaterial();
emptyMaterial.color.set("#999999");

const errorMaterial = new MeshPhysicalMaterial();
errorMaterial.color.set("#d33d3b");

const delay = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export default function EditorScene({
  camX,
  camY,
  camZ,
  sunIntensity,
  roomType = "bedroom",
  wallColor = "#A5B986",
  accentColor = "#A5B986",
  floor = "30082",
  floors = [],
  matParams,
  formats,
  displayPerf = false,
  lowResMode = false,
}) {
  //Run the frameloop for about a second to give the performance monitor time to measure:
  const [isMeasuring, setIsMeasuring] = useState(false);

  useFrame(() => {
    if (isMeasuring) {
      invalidate();
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setIsMeasuring(true);
    }, 1000);
    setTimeout(() => {
      setIsMeasuring(false);
    }, 2200);
  }, []);

  const ambientIntensity = useTransform(sunIntensity, [0, 8], [0.15, 0]);

  const floorMaterial = useRef(emptyMaterial);
  const [floorMaterialLoaded, setfloorMaterialLoaded] = useState(false);

  useEffect(() => {
    emptyMaterial.map = loader.load("/textures/loading_pattern_color.png");
    emptyMaterial.map.wrapS = emptyMaterial.map.wrapT = RepeatWrapping;
    emptyMaterial.map.repeat.set(10, 10);
    emptyMaterial.roughnessMap = loader.load(
      "/textures/loading_pattern_roughness.png"
    );
    emptyMaterial.roughnessMap.wrapS = emptyMaterial.roughnessMap.wrapT =
      RepeatWrapping;
    emptyMaterial.roughnessMap.repeat.set(10, 10);

    errorMaterial.map = loader.load("/textures/error_pattern_color.png");
    errorMaterial.map.wrapS = errorMaterial.map.wrapT = RepeatWrapping;
    errorMaterial.map.repeat.set(10, 10);
    errorMaterial.map.rotation = Math.PI;
    errorMaterial.roughnessMap = loader.load(
      "/textures/error_pattern_roughness.png"
    );
    errorMaterial.roughnessMap.wrapS = errorMaterial.roughnessMap.wrapT =
      RepeatWrapping;
    errorMaterial.roughnessMap.repeat.set(10, 10);
    errorMaterial.roughnessMap.rotation = Math.PI;
  }, []);

  useFrame(() => {
    if (!floorMaterialLoaded) {
      emptyMaterial.map.offset.addScalar(0.01);
      emptyMaterial.roughnessMap.offset.addScalar(0.01);
      invalidate();
    }
  });

  useEffect(() => {
    floorMaterial.current = emptyMaterial;
    setfloorMaterialLoaded(false);

    const func = async () => {
      await delay(200);
      const _floor = floors.find((f) => f.id === floor);
      if (_floor === undefined) {
        alert("Chosen floor " + floor + " doesn't exist.");
        return;
      }

      const material = new MeshPhysicalMaterial();
      //material.roughness = 0.5;
      material.clearcoat = matParams?.[_floor.mtrl]?.clearcoat || 0;
      material.clearcoatRoughness =
        matParams?.[_floor.mtrl]?.clearcoatRoughness || 0;
      material.reflectivity = matParams?.[_floor.mtrl]?.reflectivity || 0.5;
      material.metalness = matParams?.[_floor.mtrl]?.metalness || 0;
      Promise.all([
        loadTexture(
          _floor,
          formats?.[_floor.format]?.repeatX,
          formats?.[_floor.format]?.repeatY,
          material,
          "color",
          lowResMode
        ),
        loadTexture(
          _floor,
          formats?.[_floor.format]?.repeatX,
          formats?.[_floor.format]?.repeatY,
          material,
          "roughness",
          lowResMode
        ),
        loadTexture(
          _floor,
          formats?.[_floor.format]?.repeatX,
          formats?.[_floor.format]?.repeatY,
          material,
          "normal",
          lowResMode
        ),
        loadTexture(
          _floor,
          formats?.[_floor.format]?.repeatX,
          formats?.[_floor.format]?.repeatY,
          material,
          "clearcoatNormal",
          lowResMode,
          matParams?.[_floor.mtrl]?.clearcoatNormal
        ),
      ])
        .then(() => {
          floorMaterial.current = material;
          setfloorMaterialLoaded(true);
          /*console.log("floorMaterial", floorMaterial.current);*/
          //setDpr((dpr) => Math.max(window.devicePixelRatio, dpr));
          invalidate();
        })
        .catch((e) => {
          floorMaterial.current = errorMaterial;
          setfloorMaterialLoaded(true);
          console.error("Failed loading floorMaterial");
        });
    };
    func();
  }, [floor, floors, formats, lowResMode, matParams]);

  return (
    <>
      {displayPerf && <Perf />}
      <MotionCamera position={[camX, camY, camZ]} rotation={[0, 0, 0]} />
      <RoomCommon
        roomType={roomType}
        floor={floor}
        wallColor={wallColor}
        accentColor={accentColor}
        sunIntensity={sunIntensity}
        floorMaterial={floorMaterial.current}
        lowResMode={lowResMode}
      />
      <color attach="background" args={["#3e3e3e"]} />
      {/*<fog attach="fog" args={["#ff5020", 5, 18]} />
          <Sky scale={1000} sunPosition={[2, 0.4, 10]} />*/}
      <hemisphereLight
        color={"#eaf0ff"}
        groundColor={"#555555"}
        intensity={0.25}
      />
      <motion.ambientLight intensity={ambientIntensity} color={"#ddddff"} />
      <group position={[-0.8, 2, -0.8]}>
        <pointLight
          color={"#ffe8c4"}
          intensity={1}
          castShadow
          shadow-bias={-0.0001}
          shadow-radius={50}
          shadow-mapSize-width={lowResMode ? 1024 : 2048}
          shadow-mapSize-height={lowResMode ? 1024 : 2048}
        />
      </group>
      <SoftShadows size={30} focus={0} samples={37} />
      <BakeShadows key={"bs" + roomType + floor} />
      <EffectComposer>
        {/*<SSAO
            blendFunction={BlendFunction.MULTIPLY} // blend mode
            samples={32} // amount of samples per pixel (shouldn't be a multiple of the ring count)
            rings={7} // amount of rings in the occlusion sampling pattern. Should be a prime number.
            distanceThreshold={0.5} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
            distanceFalloff={0.1} // distance falloff. min: 0, max: 1
            rangeThreshold={0.1} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
            rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
            luminanceInfluence={1} // how much the luminance of the scene influences the ambient occlusion
            radius={0.5825} // occlusion sampling radius
            scale={1} // scale of the ambient occlusion
            bias={1} // occlusion bias
            intensity={10}
          />
          <ChromaticAberration
            blendFunction={BlendFunction.NORMAL} // blend mode
            offset={[0.0002, 0.0004]} // color offset
          />
          <DepthOfField
            focusDistance={0.004}
            focalLength={0.01}
            bokehScale={1}
        />*/}
        <ToneMapping
          blendFunction={BlendFunction.NORMAL} // blend mode
          adaptive={false} // toggle adaptive luminance map usage
          resolution={256} // texture resolution of the luminance map
          middleGrey={0.6} // middle grey factor
          minLuminance={0.01} // minimum luminance
          maxLuminance={16.0} // maximum luminance
          averageLuminance={1.0} // average luminance
          adaptationRate={1.0} // luminance adaptation rate
        />
        <Bloom
          blendFunction={BlendFunction.SCREEN}
          intensity={0.25} // The bloom intensity.
          kernelSize={5} // blur kernel size
          luminanceThreshold={0.25} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.5} // smoothness of the luminance threshold. Range is [0, 1]
        />
      </EffectComposer>
    </>
  );
}
